import React from 'react'
import './publicChat.less'
import Chat from '../../components/Chat/Chat'

const PublicChatRoute = () => {
  return (
    <div className="public-chat-container">
      <Chat />
    </div>
  )
}

export default PublicChatRoute
