import deepmerge from 'deepmerge'

const CACHE_KEY = 'appConfigCache'
const CACHE_EXPIRATION = 60 * 60 * 1000 // 60 minutes
const DEFAULT_CONFIG_URL = 'https://conf.ioni.ai'
const CONFIG_URL = process.env.REACT_APP_CONFIG_URL
const REMOTE_CONFIG_URL = `${CONFIG_URL || DEFAULT_CONFIG_URL}/${window.location.hostname}/config.json`
const LOCAL_CONFIG_PATH = '/config.json'

let inMemoryCache: any = null

/**
 * Checks the cache (in-memory or localStorage) and returns the config if valid.
 */
const getCachedConfig = (): any | null => {
  if (inMemoryCache) {
    return inMemoryCache
  }

  // Check localStorage for cached data and validate the cache expiration
  const cachedData = localStorage.getItem(CACHE_KEY)
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData)

    if (Date.now() - timestamp < CACHE_EXPIRATION) {
      inMemoryCache = data
      return data
    }
  }

  // No valid cache found
  return null
}

/**
 * Fetches the app config from a remote URL.
 */
const fetchRemoteConfig = async (): Promise<any> => {
  try {
    const response = await fetch(REMOTE_CONFIG_URL)

    if (!response.ok) {
      console.warn('Remote config unavailable')
      return null
    }

    const data = await response.json()

    // Cache the result
    inMemoryCache = data
    localStorage.setItem(
      CACHE_KEY,
      JSON.stringify({
        data,
        timestamp: Date.now(),
      }),
    )

    return data
  } catch (error) {
    console.error('Error fetching remote config:', error)
    return null
  }
}

/**
 * Fetches the app config from a local file.
 */
const fetchLocalConfig = async (): Promise<any> => {
  try {
    const response = await fetch(LOCAL_CONFIG_PATH)

    if (!response.ok) {
      console.warn('Local config unavailable')
      return null
    }

    const data = await response.json()

    // Cache the result
    inMemoryCache = data
    localStorage.setItem(
      CACHE_KEY,
      JSON.stringify({
        data,
        timestamp: Date.now(),
      }),
    )

    console.log('Local config fetched successfully')
    return data
  } catch (error) {
    console.error('Error loading local config:', error)
    return null
  }
}

/**
 * Main function to fetch app config, checking cache, remote, and local sources.
 */
export const fetchAppConfig = async (): Promise<any> => {
  // Check cached config first
  const cachedConfig = getCachedConfig()
  if (cachedConfig) {
    return cachedConfig
  }

  // Fetch the local configuration first
  const localConfig = await fetchLocalConfig()
  if (!localConfig) {
    throw new Error('Local config is required but not found')
  }

  // Try fetching the remote configuration if available
  const ignoreRemoteConfig = !CONFIG_URL

  if (!ignoreRemoteConfig) {
    const remoteConfig = await fetchRemoteConfig()
    if (remoteConfig) {
      // Merge remote config into local config
      const mergedConfig = deepmerge(localConfig, remoteConfig)
      return mergedConfig
    }
  }

  // If no remote config, return local config
  return localConfig
}
