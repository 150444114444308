import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Input, Popconfirm, Popover, Select } from 'antd'
import CustomSelect from '../../../components/CustomSelect/CustomSelect'
import TransparentButton from '../../../components/UI/TransparentButton/TransparentButton'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useAppSelector } from '../../../hooks/appHook'

import { useSuccessMessage } from '../../../hooks/useSuccessMessage'
import { useDeleteAgentMutation, useEditAgentMutation, useLazyGetAgentByIdQuery } from '../../../store/api/agent.api'
import { requiredValidator } from '../../../helpers/validators'
import { useGetStorageListQuery } from '../../../store/api/storage.api'
import { useAccountUpdateMutation } from '../../../store/api/account.api'
import { RolesEnum } from '../../../config/rolesEnum'
import { useTranslation } from 'react-i18next'
import Loader from '../../../components/UI/Loader/Loader'

const SettingsTabContent = () => {
  const { t } = useTranslation('aiAgent')
  const [form] = Form.useForm()

  /** Storage */
  const { currentAgentId, agent, agents } = useAppSelector((state) => state.agents)
  const { account } = useAppSelector((state) => state.account)
  const { user } = useAppSelector((state) => state.appState)

  /** Storage actions */
  const { data: storages } = useGetStorageListQuery()

  const [getAgent, getAgentQueryResult] = useLazyGetAgentByIdQuery()

  const [updateAgent, updateAgentMutationResult] = useEditAgentMutation()

  const [deleteAgent, deleteAgentMutationResult] = useDeleteAgentMutation()

  const [updateAccount] = useAccountUpdateMutation()

  /** State */
  const [isVisibleDeletePopover, setIsVisibleDeletePopover] = useState(false)
  const [isVisibleDefaultAgentPopover, setVisibleDefaultAgentPopover] = useState(false)
  const [selectedStorageId, setSelectedStorageId] = useState('')

  const WarningDeleteAgentPopoverContent = <div>{t('settingsTabContent.buttons.delete.popover')}</div>

  const WarningDefaultAgentPopoverContent = <div>{t('settingsTabContent.buttons.setDefault.popover')}</div>

  /** Response message handlers */
  useSuccessMessage(t('settingsTabContent.messages.updateSuccess'), updateAgentMutationResult.isSuccess)
  useSuccessMessage(t('settingsTabContent.messages.deleteSuccess'), deleteAgentMutationResult.isSuccess)

  const selectOptions = useMemo(() => {
    const values: Array<{ label: string; value: string | null }> = [
      { label: t('settingsTabContent.form.defaultStorage.label'), value: 'all' },
    ]

    storages?.forEach(({ id, name }) => {
      values.push({ value: id, label: name })
    })

    return values
  }, [storages, t])

  const isCurrentAgentDefault = useMemo(() => {
    return agent?.id === account?.defaultAgentId
  }, [agent, account])

  const onSelectStorage = (value: string) => {
    setSelectedStorageId(value)
  }

  const onSaveConfigs = (values: {
    name: string
    prompt: string
    customFunctionsList: string
    storageId: string
    realtimeEnabled: boolean
    availability: string | null
    allowTextFeedback: boolean
  }) => {
    updateAgent({
      id: currentAgentId,
      body: {
        ...values,
        storageId: selectedStorageId === 'all' ? null : selectedStorageId,
        realtimeSettings:
          user?.role == RolesEnum.ADMIN
            ? {
                enabled: values.realtimeEnabled,
              }
            : undefined,
      },
    })
  }

  const onDeleteAgent = useCallback(() => {
    deleteAgent(currentAgentId)
  }, [currentAgentId])

  const onSelectAgentAsDefault = useCallback(() => {
    if (agent?.id) {
      updateAccount({
        defaultAgentId: agent.id,
      })
    }
  }, [agent])

  useEffect(() => {
    if (currentAgentId) {
      getAgent({ id: currentAgentId })
    }
  }, [currentAgentId])

  useEffect(() => {
    const defaultValue = agent?.storageId || 'all'
    setSelectedStorageId(defaultValue)
  }, [agent])

  useEffect(() => {
    if (agent) {
      form.setFieldsValue({
        name: agent.name,
        prompt: agent.prompt,
        customFunctionsList: agent.customFunctionsList,
        realtimeEnabled: agent?.realtimeSettings?.enabled || false,
        allowTextFeedback: agent.allowTextFeedback,
      })
      if (agent.storageId) {
        setSelectedStorageId(agent.storageId)
      }
    }
  }, [agent])

  if (getAgentQueryResult.isLoading) {
    return (
      <div className="loader-container">
        <Loader fullScreenHeight />
      </div>
    )
  }

  return (
    <div className="settings-tab">
      <h2>{t('settingsTabContent.title')}</h2>
      <Form form={form} layout="vertical" onFinish={onSaveConfigs}>
        <Form.Item
          label={t('settingsTabContent.form.name.label')}
          name="name"
          rules={[{ required: true, validator: requiredValidator(t('settingsTabContent.form.name.requiredMessage')) }]}
          style={{ marginBottom: '12px' }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="storage-select-container"
          key={selectedStorageId}
          label={t('settingsTabContent.form.defaultStorage.label')}
          tooltip={{ title: t('settingsTabContent.form.defaultStorage.tooltip'), icon: <InfoCircleOutlined /> }}
        >
          <CustomSelect options={selectOptions} initialStatus={selectedStorageId} onChange={onSelectStorage} />
        </Form.Item>
        <Form.Item
          label={t('settingsTabContent.form.agentAvailability.label')}
          name="availability"
          tooltip={{
            title: t('settingsTabContent.form.agentAvailability.tooltip'),
            icon: <InfoCircleOutlined />,
          }}
        >
          <Select
            placeholder={t('settingsTabContent.form.agentAvailability.placeholder')}
            defaultValue={agent?.availability || null}
          >
            <Select.Option value={'public'}>
              {t('settingsTabContent.form.agentAvailability.options.public')}
            </Select.Option>
            <Select.Option value={null}>{t('settingsTabContent.form.agentAvailability.options.hidden')}</Select.Option>
          </Select>
        </Form.Item>
        {user?.role == RolesEnum.ADMIN && (
          <div>
            <h3>{t('settingsTabContent.form.adminSettings')}</h3>
            <Form.Item
              label={t('settingsTabContent.form.customPrompt.label')}
              name="prompt"
              style={{ width: 500 }}
              tooltip={{
                title: t('settingsTabContent.form.customPrompt.tooltip'),
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input.TextArea autoSize={{ minRows: 5, maxRows: 6 }} />
            </Form.Item>
            <Form.Item
              label={t('settingsTabContent.form.customFunctionsList.label')}
              name="customFunctionsList"
              tooltip={{
                title: t('settingsTabContent.form.customFunctionsList.tooltip'),
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input placeholder={t('settingsTabContent.form.customFunctionsList.placeholder')} />
            </Form.Item>
            {/* realtime section*/}
            <Form.Item
              label={t('settingsTabContent.form.realtimeEnabled.label')}
              name="realtimeEnabled"
              tooltip={{
                title: t('settingsTabContent.form.realtimeEnabled.tooltip'),
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select>
                <Select.Option value={true}>
                  {t('settingsTabContent.form.realtimeEnabled.options.enabled')}
                </Select.Option>
                <Select.Option value={false}>
                  {t('settingsTabContent.form.realtimeEnabled.options.disabled')}
                </Select.Option>
              </Select>
            </Form.Item>
            {/*// allow text feedback section*/}
            <Form.Item
              label={t('settingsTabContent.form.textFeedback.label')}
              name="allowTextFeedback"
              tooltip={{
                title: t('settingsTabContent.form.textFeedback.tooltip'),
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select defaultValue={agent?.allowTextFeedback || false}>
                <Select.Option value={true}>{t('settingsTabContent.form.textFeedback.options.yes')}</Select.Option>
                <Select.Option value={false}>{t('settingsTabContent.form.textFeedback.options.no')}</Select.Option>
              </Select>
            </Form.Item>
          </div>
        )}
        <div className="update-agent-form-buttons" style={{ marginTop: 25 }}>
          <Popover content={WarningDeleteAgentPopoverContent} open={isVisibleDeletePopover}>
            <Popconfirm
              icon={null}
              title={t('settingsTabContent.buttons.delete.confirmation.title')}
              description={t('settingsTabContent.buttons.delete.confirmation.description', { agentName: agent?.name })}
              onConfirm={onDeleteAgent}
              okText={t('settingsTabContent.buttons.delete.confirmation.okText')}
              cancelText={t('settingsTabContent.buttons.delete.confirmation.cancelText')}
            >
              <div
                onMouseEnter={() => {
                  if (agents.length === 1) {
                    setIsVisibleDeletePopover(true)
                  }
                }}
                onMouseLeave={() => {
                  setIsVisibleDeletePopover(false)
                }}
              >
                <TransparentButton
                  disabled={agents.length === 1 || isCurrentAgentDefault}
                  className="delete-agent-button"
                  title={t('settingsTabContent.buttons.delete.title')}
                />
              </div>
            </Popconfirm>
          </Popover>
          <TransparentButton
            className="create-agent-button"
            title={t('settingsTabContent.buttons.save.title')}
            htmlType="submit"
          />
        </div>
        <Popover content={WarningDefaultAgentPopoverContent} open={isVisibleDefaultAgentPopover}>
          <div
            className="set-default-agent-button-wrapper"
            onMouseEnter={() => {
              if (isCurrentAgentDefault) {
                setVisibleDefaultAgentPopover(true)
              }
            }}
            onMouseLeave={() => {
              setVisibleDefaultAgentPopover(false)
            }}
          >
            <TransparentButton
              disabled={isCurrentAgentDefault}
              className="set-default-agent-button"
              title={t('settingsTabContent.buttons.setDefault.title')}
              onClick={onSelectAgentAsDefault}
            />
          </div>
        </Popover>
      </Form>
    </div>
  )
}

export default SettingsTabContent
