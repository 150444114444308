import { createApi } from '@reduxjs/toolkit/query/react'
import { ACCESS_TOKEN } from '../../config/consts'
import { LocalStorage } from 'ts-localstorage'
import { GetChatConfig } from '../../types/public.type'
import { Conversation, ConversationMessageRequestBody, EditConversationRequest } from '../../types/conversation.type'
import baseQueryWithReauth from '../baseQueryWithReauth'

type SendChatMessageRequest = {
  conversationId: string
  message: ConversationMessageRequestBody
}

type CreateConversationRequest = {
  accountId?: string
  agentId?: string
  storageId?: string
  customerData: {
    name: string
    email: string
  } | null
}

export const publicApi = createApi({
  reducerPath: 'publicApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createConversation: builder.mutation<Conversation, CreateConversationRequest>({
      query: ({ agentId, accountId, storageId, customerData }) => ({
        url: `/chat/`,
        method: 'POST',
        body: {
          accountId: accountId || undefined,
          agentId: agentId || undefined,
          storageId: storageId || undefined,
          customerData,
        },
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),

    sendChatMessage: builder.mutation<Conversation, SendChatMessageRequest>({
      query: ({ conversationId, message }) => ({
        url: `/chat/${conversationId}`,
        method: 'POST',
        body: { message },
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    getChatConfig: builder.query<GetChatConfig, { id: string; agentId?: string }>({
      query: ({ id, agentId }) => ({
        url: `chat/settings/${id}${agentId ? `?agentId=${agentId}` : ''}`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    getConversation: builder.query<Conversation, { conversationId: string }>({
      query: ({ conversationId }) => ({
        url: `chat/${conversationId}`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    editConversation: builder.mutation<Conversation, EditConversationRequest>({
      query: ({ body, id }) => ({
        url: `chat/${id}`,
        method: 'PATCH',
        body,
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
  }),
})

export const {
  useLazyGetChatConfigQuery,
  useSendChatMessageMutation,
  useGetConversationQuery,
  useLazyGetConversationQuery,
  useCreateConversationMutation,
  useEditConversationMutation,
} = publicApi
