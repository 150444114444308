import React, { memo } from 'react'
import { Button, Divider, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

interface IProps {
  initialStatus: string
  onChange: (value: any) => void
  options: Array<{ label: string; value: string | null }>
  createButtonTitle?: string
  onPressCreteButton?: () => void
}

const CustomSelect = ({ initialStatus, onChange, options, createButtonTitle, onPressCreteButton }: IProps) => {
  const onPressCreate = () => {
    onPressCreteButton && onPressCreteButton()
  }

  return (
    <Select
      defaultValue={initialStatus}
      onChange={onChange}
      options={options}
      className="storage-select"
      dropdownRender={(menu) => (
        <>
          {menu}
          {createButtonTitle && (
            <>
              <Divider style={{ margin: '8px 0' }} />
              <Button type="text" icon={<PlusOutlined />} onClick={onPressCreate} style={{ width: '100%' }}>
                {createButtonTitle}
              </Button>
            </>
          )}
        </>
      )}
    />
  )
}

export default memo(CustomSelect)
