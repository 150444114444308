import React, { LegacyRef } from 'react'
import { Message } from '../../../types/storage.type'

import './chatMessagesHistory.less'
import ChatMessage from '../ChatMessage/ChatMessage'
import { useTranslation } from 'react-i18next'
import Loader from '../../UI/Loader/Loader'

type IProps = {
  lastMessageRef?: LegacyRef<HTMLDivElement>
  lastUserMessageRef?: LegacyRef<HTMLDivElement>
  chatRef?: LegacyRef<HTMLUListElement>
  messages: Array<Message> | undefined
  conversationId: string
  allowLikeDislikeActions?: boolean
  isLoading: boolean
  showRoles?: boolean
  isScrollable?: boolean
  refetchConversation: () => void
}

const ChatMessagesHistory = ({
  lastMessageRef,
  lastUserMessageRef,
  chatRef,
  isLoading,
  messages,
  conversationId,
  allowLikeDislikeActions = false,
  showRoles = false,
  isScrollable = true,
  refetchConversation,
}: IProps) => {
  const { t } = useTranslation('components')

  const messagesListLength = messages?.length || 0

  if (isLoading) {
    return <Loader isLoading={true} />
  }

  const lastUserMessageIndex = messagesListLength ? messages?.map((message: Message) => message.role).lastIndexOf('user') : undefined;

  return (
    <div className="chat-messages-list-container">
      {messagesListLength ? (
        <ul className="chat-messages-list" style={{ overflowY: isScrollable ? 'scroll' : 'visible' }} ref={chatRef}>
          {messages?.map((message: Message, index) => {
            const isLastMessage = index === messagesListLength - 1
            const isLastUserMessage = index === lastUserMessageIndex;
            return (
              <ChatMessage
                message={message}
                key={message.id || index}
                showRoles={showRoles}
                lastMessageRef={isLastMessage ? lastMessageRef : undefined}
                lastUserMessageRef={isLastUserMessage ? lastUserMessageRef : undefined}
                conversationId={conversationId}
                allowLikeDislikeActions={allowLikeDislikeActions}
                refetchConversation={refetchConversation}
              />
            )
          })}
        </ul>
      ) : (
        <div className="no-messages-wrapper">
          <h1 className="no-messages-title">{t('chat.chatMessagesHistory.welcome')}</h1>
        </div>
      )}
    </div>
  )
}

export default ChatMessagesHistory
