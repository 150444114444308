import React from 'react'
import { useAppSelector } from '../../hooks/appHook'
import KnowledgeBasePageHeader from './components/KnowledgeBasePageHeader/KnowledgeBasePageHeader'
import './knowledgeBase.less'

import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { KnowledgeBaseRoutes } from '../../types/enums/knowledge-base-routes.enum'

const KnowledgeBase = () => {
  const { storages } = useAppSelector((state) => state.storage)
  const { t } = useTranslation('knowledgeBase')
  const location = useLocation()

  if (location.pathname === '/storage') {
    const queryParams = location.search
    return <Navigate to={`/storage/${KnowledgeBaseRoutes.addData}${queryParams}`} replace />
  }

  return (
    <>
      <KnowledgeBasePageHeader title={t('headerTitle')} />
      <div className="container">
        {storages.length ? <Outlet /> : <div className="empty-storages-container">{t('noStorageMessage')}</div>}
      </div>
    </>
  )
}

export default KnowledgeBase
