import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChatSettingsState } from '../../types/public.type'
import { publicApi } from '../api/pubic.api'

export const initialState: ChatSettingsState = {
  chatSettings: {},
  activeChatConversationId: null,
  activeChatAgentId: null,
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setActiveChatConversationId: (state, action: PayloadAction<string | null>) => {
      state.activeChatConversationId = action.payload
    },
    setActiveChatAgentId: (state, action: PayloadAction<string | null>) => {
      state.activeChatAgentId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(publicApi.endpoints?.getChatConfig.matchFulfilled, (state, action) => {
      state.chatSettings = action.payload.chatSettings
    })
  },
})

export const { setActiveChatConversationId, setActiveChatAgentId } = chatSlice.actions

export default chatSlice.reducer
