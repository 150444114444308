import { createApi } from '@reduxjs/toolkit/query/react'
import { LocalStorage } from 'ts-localstorage'
import baseQueryWithReauth from '../baseQueryWithReauth'
import { Conversation, ConversationStats, GetConversationResponse } from '../../types/conversation.type'
import { ACCESS_TOKEN } from '../../config/consts'

export const conversationApi = createApi({
  reducerPath: 'conversationApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getConversationList: builder.query<GetConversationResponse, { agentId?: string; pageSize?: number; page?: number }>(
      {
        query: ({ agentId, pageSize, page }) => {
          const queryParams = new URLSearchParams()

          if (agentId) {
            queryParams.append('agentId', agentId)
          }
          if (pageSize) {
            queryParams.append('pageSize', pageSize.toString())
          }
          if (page) {
            queryParams.append('page', page.toString())
          }

          return {
            url: `conversations?${queryParams.toString()}`,
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
              authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
            },
          }
        },
      },
    ),
    getConversationStats: builder.query<ConversationStats, void>({
      query: () => ({
        url: `conversations/stats`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    getConversationById: builder.query<Conversation, { id: string }>({
      query: ({ id }) => ({
        url: `conversations/${id}`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),

    deleteConversation: builder.mutation<void, string>({
      query: (id) => ({
        url: `conversations/${id}`,
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
  }),
})

export const {
  useGetConversationListQuery,
  useLazyGetConversationListQuery,
  useGetConversationStatsQuery,
  useLazyGetConversationByIdQuery,
  useGetConversationByIdQuery,
  useDeleteConversationMutation,
} = conversationApi
