import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Popconfirm, Popover } from 'antd'
import { useAppSelector } from '../../../../../hooks/appHook'
import { useSuccessMessage } from '../../../../../hooks/useSuccessMessage'
import {
  useDeleteStorageMutation,
  useGetStorageListQuery,
  useLazyGetStorageByIdQuery,
  useUpdateStorageMutation,
} from '../../../../../store/api/storage.api'
import { EditStorageRequest } from '../../../../../types/storage.type'
import TestChatButton from '../../../../../components/TestChatButton/TestChatButton'
import EditStorageForm from '../../forms/EditStorageForm/EditStorageForm'
import TransparentButton from '../../../../../components/UI/TransparentButton/TransparentButton'
import './settingsTab.less'
import { useTranslation } from 'react-i18next'

const SettingsTab = () => {
  const { t } = useTranslation('knowledgeBase')

  const location = useLocation()
  const navigate = useNavigate()

  const [updateStorageForm] = Form.useForm()

  /** Storage */
  const { currentStorageId, storages } = useAppSelector((state) => state.storage)

  const { refetch } = useGetStorageListQuery()

  /** Storage Actions */
  const [getStorage, getStorageQueryResult] = useLazyGetStorageByIdQuery()

  const [updateStorage, updateStorageMutationResult] = useUpdateStorageMutation()

  const [deleteStorage, deleteStorageMutationResult] = useDeleteStorageMutation()

  /** State */
  const [language, setLanguage] = useState<string>('English')
  const [isVisibleDeletePopover, setIsVisibleDeletePopover] = useState<boolean>(false)

  /** Response message handlers */
  useSuccessMessage(t('settingsTab.updateSuccess'), updateStorageMutationResult.isSuccess)
  useSuccessMessage(t('settingsTab.deleteSuccess'), deleteStorageMutationResult.isSuccess)

  const currentStorage = useMemo(() => {
    return storages?.find((storage) => storage.id === currentStorageId) || null
  }, [currentStorageId, storages])

  useEffect(() => {
    getStorage({ id: currentStorageId })
  }, [currentStorageId])

  useEffect(() => {
    if (getStorageQueryResult.currentData && !getStorageQueryResult.isError) {
      const { name, distanceThreshold, resultsQuantity, language } = getStorageQueryResult.currentData

      updateStorageForm.setFieldsValue({ name, distanceThreshold, resultsQuantity })
      setLanguage(language)
    }
  }, [getStorageQueryResult])

  useEffect(() => {
    if (deleteStorageMutationResult.isSuccess) {
      const queryParams = new URLSearchParams(location.search)
      const queryStorageId = queryParams.get('storageId')

      if (queryStorageId && currentStorageId && currentStorageId !== queryStorageId) {
        queryParams.set('storageId', currentStorageId)
      }
      if (queryStorageId && !currentStorageId) {
        queryParams.delete('storageId')
      }
      /** Update the URL with the new query parameter, replacing the current entry without adding a new one to the history */
      navigate({ search: queryParams.toString() }, { replace: true })
    }
  }, [deleteStorageMutationResult, currentStorageId])

  const onCompleteFormCreateStorage = useCallback(
    (values: Partial<Storage>) => {
      updateStorage({ id: currentStorageId, body: { ...values, language } } as EditStorageRequest).then(() => refetch())
    },
    [language, currentStorageId],
  )

  const onSaveConfigs = useCallback(() => {
    updateStorageForm.submit()
  }, [])

  const onDeleteStorage = useCallback(() => {
    deleteStorage(currentStorageId)
  }, [currentStorageId])

  const onSelectLanguage = useCallback((lang: string) => {
    setLanguage(lang)
  }, [])

  const WarningDeleteAgentPopoverContent = <div>{t('settingsTab.deleteWarning')}</div>

  return (
    <div className="language-settings-tab">
      <div className="test-chat-button-container">
        <TestChatButton />
      </div>
      <div className="storage-form-container">
        <div className="tab-header">
          <h2>{t('settingsTab.headerTitle')}</h2>
        </div>
        <EditStorageForm
          storageForm={updateStorageForm}
          language={language}
          setLanguage={onSelectLanguage}
          onSubmitForm={onCompleteFormCreateStorage}
        />
        <div className="storage-form-buttons">
          <Popover content={WarningDeleteAgentPopoverContent} open={isVisibleDeletePopover}>
            <Popconfirm
              icon={null}
              title={t('settingsTab.deleteTitle')}
              description={t('settingsTab.deleteDescription', { name: currentStorage?.name })}
              onConfirm={onDeleteStorage}
              okText={t('settingsTab.deleteOk')}
              cancelText={t('settingsTab.deleteCancel')}
            >
              <div
                onMouseEnter={() => {
                  if (storages.length === 1) {
                    setIsVisibleDeletePopover(true)
                  }
                }}
                onMouseLeave={() => {
                  setIsVisibleDeletePopover(false)
                }}
              >
                <TransparentButton
                  disabled={storages.length === 1}
                  className="transparent-button-small delete-storage-button"
                  title={t('settingsTab.deleteTitle')}
                />
              </div>
            </Popconfirm>
          </Popover>
          <TransparentButton
            className="transparent-button-small create-storage-button"
            title={t('settingsTab.saveButton')}
            onClick={onSaveConfigs}
          />
        </div>
      </div>
    </div>
  )
}

export default SettingsTab
