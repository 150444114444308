import React, { useEffect } from 'react'
import ChatMessagesHistory from '../../../../components/Chat/ChatMessagesHistory/ChatMessagesHistory'

import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDeleteConversationMutation, useGetConversationByIdQuery } from '../../../../store/api/conversation.api'
import { Button, Popconfirm, Tooltip } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import LikeDislikeConversationButtons from '../../../../components/Feedback/LikeDislikeConversationButtons/LikeDislikeConversationButtons'
import './conversation.less'
import ConversationTextFeedback from '../../../../components/Feedback/ConversationTextFeedback/ConversationTextFeedback'
import { useAppSelector } from '../../../../hooks/appHook'
import { useGetAgentListQuery } from '../../../../store/api/agent.api'
import ConversationTextFeedbackHistory from '../../../../components/Feedback/ConversationTextFeedbackHistory/ConversationTextFeedbackHistory'
import ConversationLikedMessages from '../../../../components/Feedback/ConversationLikedMessages/ConversationLikedMessages'

export const Conversation = () => {
  const { t } = useTranslation('conversations')

  const navigate = useNavigate()
  const { conversationId } = useParams()
  const { currentAgentId, agents } = useAppSelector((state) => state.agents)
  const currentAgent = agents?.find((agent) => agent.id === currentAgentId)
  const isTextFeedbackAllowed = !!currentAgent?.allowTextFeedback

  const { user } = useAppSelector((state) => state.appState)

  /** API */
  const { data, isFetching, refetch } = useGetConversationByIdQuery({ id: conversationId || '' })
  const [deleteConversation, deleteConversationResult] = useDeleteConversationMutation()
  useGetAgentListQuery()

  const myFeedback = data?.analytics?.feedback?.find((f) => f.userId === user.id)

  /** HANDLERS */
  const onDelete = () => {
    deleteConversation(conversationId || '')
  }

  /** useEffect */
  useEffect(() => {
    if (deleteConversationResult.isSuccess) {
      navigate(-1)
    }
  }, [deleteConversationResult.isSuccess])

  //feedback

  const filteredTextFeedback = data?.analytics?.feedback?.filter((f) => f.feedback)
  const filteredMessages = data?.messages.filter((m) =>
    m.feedback?.some((f) => f.rating === 'like' || f.rating === 'dislike'),
  )

  const showFeedbackSection =
    (filteredTextFeedback && filteredTextFeedback.length) || (filteredMessages && filteredMessages.length)

  return (
    <div className="conversation">
      <div className="innerContainer">
        <div className="content">
          <div className="header">
            <h1 className="headerTitle">{data?.name || data?.id || t('headerTitle')}</h1>
            <div className="actions-buttons-wrapper">
              <LikeDislikeConversationButtons
                conversationId={conversationId || ''}
                feedback={data?.analytics?.feedback}
                isTextFeedbackAllowed={isTextFeedbackAllowed}
              />

              <Popconfirm
                title={t('conversation.confirmDelete')}
                onConfirm={() => onDelete()}
                okText={t('yes')}
                cancelText={t('no')}
                placement={'leftTop'}
              >
                <Tooltip title={t('conversation.deleteConversation')}>
                  <Button icon={<DeleteOutlined />} />
                </Tooltip>
              </Popconfirm>
            </div>
          </div>
          {isTextFeedbackAllowed && !myFeedback && (
            <div>
              <ConversationTextFeedback conversationId={conversationId || ''} />
            </div>
          )}

          {!!showFeedbackSection && (
            <>
              <h2 className="title">{t('conversation.title')}</h2>

              <ConversationTextFeedbackHistory feedback={data?.analytics?.feedback} />
              <div style={{ margin: '10px 0' }} />
              <ConversationLikedMessages messages={data?.messages || []} showMessagesWith="like" />
              <div style={{ margin: '10px 0' }} />
              <ConversationLikedMessages messages={data?.messages || []} showMessagesWith="dislike" />
            </>
          )}
          <ChatMessagesHistory
            messages={data?.messages || []}
            isLoading={isFetching}
            conversationId={conversationId || ''}
            allowLikeDislikeActions
            showRoles
            isScrollable={false}
            refetchConversation={refetch}
          />
        </div>
      </div>
    </div>
  )
}
