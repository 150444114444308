import React, { memo } from 'react'
import { Alert, Layout } from 'antd'
import { Link } from 'react-router-dom'
import Logo from '../../../../components/UI/Logos/Logo'
import AccountMenu from '../../../../components/AccountMenu/AccountMenu'
import { LocalStorage } from 'ts-localstorage'
import { ADMIN_USER_ID } from '../../../../config/consts'
import { IUser } from '../../../../types/profile.type'
import ActionButtonsArea from '../ActionButtonsArea/ActionButtonsArea'
import useWindowSize from '../../../../hooks/useWindowsSize'

const { Header: LayoutHeader } = Layout

interface IProps {
  user: IUser
  toggleMobileMenu: () => void
}

const Header = ({ user, toggleMobileMenu }: IProps) => {
  const adminUserId = LocalStorage.getItem(ADMIN_USER_ID)

  const { isDesktop, isMobile } = useWindowSize()

  return (
    <LayoutHeader>
      <div className="header">
        {
          isMobile && (
            <>
              <ActionButtonsArea isMenuOpen={false} onToggleMenu={null} toggleMobileMenu={toggleMobileMenu} />
              <Link to="/" className="logo" style={{ marginRight: '33px' }}>
                <Logo name="ioniBlack" width={'78'} height={'22'} />
              </Link>
            </>
          )
        }
        <div className={isDesktop ? 'right-header-item' : 'center-item'}>
          <AccountMenu />
        </div>

        {!!adminUserId && <p id="admin-account-email">{user.email}</p>}

        {user?.status === 'unverified' && (
          <Alert
            message={
              <>
                Please verify your email address {user.email} to use IONI. You can resend verification email on
                your{' '}
                <Link to="/profile">profile page</Link>.
              </>
            }
            type="warning"
            style={{ marginTop: 20 }}
          />
        )}
      </div>
    </LayoutHeader>
  )
}

export default memo(Header)
