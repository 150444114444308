import React from 'react'
import { Collapse, Divider } from 'antd'
import './conversationTextFeedbackHistory.less'
import { ConversationFeedback } from '../../../types/conversation.type'
import { useTranslation } from 'react-i18next'
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons'

const { Panel } = Collapse

type IProps = {
  feedback: ConversationFeedback[] | undefined
}

const ConversationTextFeedbackHistory = ({ feedback = [] }: IProps) => {
  const { t } = useTranslation('conversations')
  const filteredFeedback = feedback.filter((f) => f.feedback)

  if (!filteredFeedback || filteredFeedback?.length === 0) {
    return <></>
  }

  return (
    <div className="conversation-text-feedback-history">
      <Collapse bordered={false} size="large" expandIconPosition="end">
        <Panel
          header={<h3 className="title">{t('conversation.conversationTextFeedbackHistory.title')}</h3>}
          key="2"
          style={{ userSelect: 'none', position: 'relative' }}
        >
          <ul style={{ listStyle: 'none' }}>
            {filteredFeedback?.map((f, index) => {
              return (
                <React.Fragment key={index}>
                  <li style={{ position: 'relative' }}>
                    {f.feedback}
                    {f.rating === 'like' ? (
                      <>
                        <LikeOutlined className="rating-icon" />
                      </>
                    ) : (
                      <>
                        <DislikeOutlined className="rating-icon" />
                      </>
                    )}
                  </li>
                  {index < filteredFeedback.length - 1 && <Divider style={{ margin: '10px 0' }} />}
                </React.Fragment>
              )
            })}
          </ul>
        </Panel>
      </Collapse>
    </div>
  )
}

export default ConversationTextFeedbackHistory
