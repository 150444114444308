import React from 'react'
import TransparentButton from '../UI/TransparentButton/TransparentButton'
import { useAppSelector } from '../../hooks/appHook'
import { useTranslation } from 'react-i18next'

const TestChatButton = () => {
  const { account } = useAppSelector((state) => state.account)
  const { currentStorageId } = useAppSelector((state) => state.storage)
  const { t } = useTranslation('components')

  const handleOnPressTestChat = () => {
    if (account?.id && currentStorageId) {
      window.open(`${location.origin}/c/${account.id}?${currentStorageId && `storageId=${currentStorageId}`}`, '_blank')
    }
  }
  return <TransparentButton onClick={handleOnPressTestChat} title={t('testChatButton.title')} style={{ width: 150 }} />
}

export default TestChatButton
