import { createSlice } from '@reduxjs/toolkit'
import { UsersState } from '../../types/users.type'
import { usersApi } from '../api/users.api'

export const initialState: UsersState = {
  users: [],
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(usersApi.endpoints.getUsers.matchFulfilled, (state, action) => {
      state.users = action.payload
    })
  },
})

export const adminActionCreators = usersSlice.actions

export default usersSlice.reducer
