import React, { memo } from 'react'
import { Drawer, Layout } from 'antd'
import { SiderProps } from 'antd/es/layout'
import './sidebar.less'
import NavigationMenu from '../../../../components/NavigationMenu/NavigationMenu'
import useWindowSize from '../../../../hooks/useWindowsSize'

const { Sider } = Layout

interface SidebarProps extends SiderProps {
  isDesktopMenuOpen: boolean
  isMobileMenuOpen: boolean
  toggleMobileMenu: () => void
}

const Sidebar = ({ isDesktopMenuOpen, isMobileMenuOpen, toggleMobileMenu, ...siderProps }: SidebarProps) => {
  const { isMobile, isDesktop } = useWindowSize()

  const menuBackground = `linear-gradient(
  180deg,
  #e6ecf4 0%,
  #d5e2f2 20%,
  #d2e2f1 40%,
  #d6d7f4 60%,
  #e6d8f2 80%,
  #e4d1f2 100%
)`

  return (
    <>
      {/* Desktop Sidebar */}
      {isDesktop && (
        <Sider
          style={{ background: menuBackground }}
          width={isDesktopMenuOpen ? 270 : 0}
          id="ioni-layout-sider"
          breakpoint="lg"
          collapsedWidth="0"
          className="ioni-layout-sider"
          {...siderProps}
        >
          <NavigationMenu />
        </Sider>
      )}

      {/* Mobile Sidebar */}
      <Drawer
        placement="left"
        closable={true}
        onClose={toggleMobileMenu}
        open={isMobileMenuOpen && isMobile}
        style={{ width: 'fit-content' }}
        bodyStyle={{
          padding: 0,
          width: 'fit-content',
          background: menuBackground,
        }}
        contentWrapperStyle={{
          width: 'fit-content',
        }}
      >
        <div
          style={{
            background: menuBackground,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',
          }}
          id="ioni-layout-sider"
        >
          <NavigationMenu />
        </div>
      </Drawer>
    </>
  )
}

export default memo(Sidebar)
