import { createApi } from '@reduxjs/toolkit/query/react'
import { IUser } from '../../types/profile.type'
import { IAuth, ITockens } from '../../types/auth.type'
import { ACCESS_TOKEN } from '../../config/consts'
import { IInviteUser } from '../../routes/auth/interfaces/IInviteUser'
import { LocalStorage } from 'ts-localstorage'

import { notification } from 'antd'
import { setAccessToken } from '../slices/appState.slice'
import baseQueryWithReauth from '../baseQueryWithReauth'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    signIn: builder.mutation<ITockens, IAuth>({
      query: (args) => ({
        url: `auth/signin/`,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: args,
      }),
    }),
    signUp: builder.mutation<ITockens, IAuth>({
      query: (args) => ({
        url: `auth/signup/`,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: args,
      }),
    }),
    forgotPassword: builder.mutation<void, { email: string }>({
      query: (body) => ({
        url: `auth/forgot-password/`,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body,
      }),
    }),
    resetPassword: builder.mutation<
      ITockens,
      { email?: string; code?: string; password?: string; isInvitation?: boolean }
    >({
      query: (body) => ({
        url: `auth/reset-password/`,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled
          if (res?.data) {
            dispatch(setAccessToken(res?.data))
          }
        } catch (e: any) {
          notification.error(e.message)
        }
      },
    }),
    verifyEmail: builder.mutation<void, IInviteUser>({
      query: (args) => ({
        url: `auth/verify/`,
        params: args,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
      }),
    }),
    googleOauth: builder.mutation<IUser, void>({
      query: () => ({
        url: `auth/google/`,
        method: 'GET',
      }),
    }),
    resendCode: builder.query({
      query: ({ email }: { email: string }) => ({
        url: `auth/resend-code/${email}`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
  }),
})

export const {
  useSignInMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyEmailMutation,
  useGoogleOauthMutation,
  useLazyResendCodeQuery,
} = authApi

export const { signIn } = authApi.endpoints
