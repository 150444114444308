import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { shallowEqual } from 'react-redux'
import { Form, Space } from 'antd'
import FirstStep from '../steps/step1/FirstStep'
import SecondStep from '../steps/step2/SecondStep'
import ThirdStep from '../steps/step3/ThirdStep'
import AppPrimaryButton from '../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import Icon from '../../../../components/UI/Icons/Icons'
import { useAccountUpdateMutation } from '../../../../store/api/account.api'
import { useSuccessMessage } from '../../../../hooks/useSuccessMessage'
import { useAppSelector } from '../../../../hooks/appHook'
import { useTranslation } from 'react-i18next'

interface IProps {
  stepTitle: string
  currentStep: number
  moveNextStep: () => void
  movePrevStep: () => void
}

const StepsForm = ({ stepTitle, currentStep, moveNextStep, movePrevStep }: IProps) => {
  const { t } = useTranslation('onboarding')
  const navigate = useNavigate()

  const [stepsForm] = Form.useForm()

  /** Storage */
  const onboarding = useAppSelector((state) => state.onboarding, shallowEqual)

  /** Storage actions */
  const [updateAccount, updAccResp] = useAccountUpdateMutation()

  /** Response message handlers */
  useSuccessMessage(
    'Thank you for registration. Please check your email inbox for further instructions.',
    updAccResp.status === 'fulfilled',
  )

  const onFinishStep = useCallback(() => {
    if (currentStep === 2) {
      updateAccount({
        data: {
          companyName: onboarding.companyName,
          companySize: onboarding.companySize,
          useCase: onboarding.useCase,
        },
      })
      return
    }
    moveNextStep()
  }, [currentStep, onboarding])

  useEffect(() => {
    if (updAccResp.status === 'fulfilled') {
      navigate('/')
    }
  }, [updAccResp.status])

  return (
    <Form
      form={stepsForm}
      className="poll-form"
      name="poll-form"
      autoComplete="off"
      requiredMark={false}
      onFinish={onFinishStep}
    >
      <div className="poll-content">
        <div className="poll-content-title">{stepTitle}</div>
        {currentStep === 0 && <FirstStep />}
        {currentStep === 1 && <SecondStep />}
        {currentStep === 2 && <ThirdStep />}
      </div>
      <Space size={10} className="form-item-action-button">
        {currentStep !== 0 && (
          <AppPrimaryButton
            size="large"
            type="primary"
            onClick={movePrevStep}
            loading={updAccResp.isLoading}
            className={'onboarding-back-btn'}
          >
            <Icon name={'buttonRight'} />
            <span>{t('stepsForm.back')}</span>
          </AppPrimaryButton>
        )}
        <AppPrimaryButton
          size="large"
          type="primary"
          htmlType="submit"
          loading={updAccResp.isLoading}
          disabled={currentStep === 2 && !onboarding.useCase}
        >
          <>
            <span>{currentStep === 2 ? t('stepsForm.finish') : t('stepsForm.next')}</span>
            <Icon name="buttonRight" />
          </>
        </AppPrimaryButton>
      </Space>
    </Form>
  )
}

export default StepsForm
