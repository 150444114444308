import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Icon from '../UI/Icons/Icons'
import { KnowledgeBaseRoutes } from '../../types/enums/knowledge-base-routes.enum'
import ConversationsListMenu from '../Chat/ConversationsListMenu/ConversationsListMenu'
import './navigationMenu.less'
import { Menu, MenuProps } from 'antd'
import { useAppSelector } from '../../hooks/appHook'
import { RolesEnum } from '../../config/rolesEnum'
import { useTranslation } from 'react-i18next'

const NavigationMenu = () => {
  const [stateOpenKeys, setStateOpenKeys] = useState<string[]>([])
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const { account } = useAppSelector((state) => state.account)
  const { user } = useAppSelector((state) => state.appState)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation('components')

  const getPathWithConversationId = (basePath: string): string => {
    const currentSearchParams = new URLSearchParams(location.search)
    const conversationId = currentSearchParams.get('conversationId')

    return conversationId ? `${basePath}?conversationId=${conversationId}` : basePath
  }

  const chatPath = getPathWithConversationId(`/c/${account?.id}`)

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    openKeys.length > 1 ? setStateOpenKeys(openKeys.slice(1)) : setStateOpenKeys(openKeys)
    setSelectedKeys([])
  }

  const onSelect: MenuProps['onSelect'] = ({ key, selectedKeys }) => {
    selectedKeys.length > 1 ? setSelectedKeys(selectedKeys.slice(1)) : setSelectedKeys(selectedKeys)
    if (key !== 'conversations-list' && !key.includes('/storage')) {
      setStateOpenKeys([])
    }
  }

  return (
    <Menu mode="inline" openKeys={stateOpenKeys} onOpenChange={onOpenChange} selectedKeys={selectedKeys}
          onSelect={onSelect}
          style={{ minWidth: 256, background: 'transparent' }} inlineIndent={14}>
      {/* Chat Section */}
      <Menu.SubMenu
        key={`/c/${account?.id}`}
        icon={<Icon name="conversations" width={22} height={20} style={{ marginRight: '-4px' }} />}
        onTitleClick={() => navigate(chatPath)}
        title={<Link className="menu-header" to={chatPath}>{t('navigationMenu.chatSection.title')}</Link>}
      >
        <Menu.Item key="conversations-list" className="menu-content-wrapper">
          <ConversationsListMenu />
        </Menu.Item>
      </Menu.SubMenu>

      {/* Knowledge Base Section */}
      <Menu.SubMenu
        key="/storage"
        icon={<Icon name="knowledgeBase" width={20} height={20} style={{ marginRight: '-2px' }} />}
        title={<div className="menu-header">{t('navigationMenu.knowledgeBaseSection.title')}</div>}
        onTitleClick={() => navigate(`/storage/${KnowledgeBaseRoutes.addData}`)}
        className="custom-submenu-as-list"
      >
        <Menu.Item
          key="/storage/add_data"
          className={pathname.includes(`/storage/${KnowledgeBaseRoutes.addData}`) ? 'ant-menu-item-selected' : ''}
        >
          <Link to={`/storage/${KnowledgeBaseRoutes.addData}`} className="custom-submenu-item">
            {t('navigationMenu.knowledgeBaseSection.addNewData')}
          </Link>
        </Menu.Item>
        <Menu.Item key="/storage/documents">
          <Link to={`/storage/${KnowledgeBaseRoutes.documents}`} className="custom-submenu-item">
            {t('navigationMenu.knowledgeBaseSection.documents')}
          </Link>
        </Menu.Item>
        <Menu.Item key="/storage/search_and_edit">
          <Link to={`/storage/${KnowledgeBaseRoutes.searchAndEdit}`} className="custom-submenu-item">
            {t('navigationMenu.knowledgeBaseSection.searchAndEditData')}
          </Link>
        </Menu.Item>
        <Menu.Item key="/storage/review">
          <Link to={`/storage/${KnowledgeBaseRoutes.review}`} className="custom-submenu-item">
            {t('navigationMenu.knowledgeBaseSection.reviewData')}
          </Link>
        </Menu.Item>
        <Menu.Item key="/storage/settings">
          <Link to={`/storage/${KnowledgeBaseRoutes.settings}`} className="custom-submenu-item">
            {t('navigationMenu.knowledgeBaseSection.settings')}
          </Link>
        </Menu.Item>
      </Menu.SubMenu>

      {/* AI Agents Section */}
      <Menu.Item
        key="/agent"
        icon={
          <Icon name="agents" width={20} height={20} className="custom-menu-item" style={{ marginRight: '-2px' }} />
        }
      >
        <Link to="/agent">{t('navigationMenu.aiAgentsSection.title')}</Link>
      </Menu.Item>

      {/* Conversations Section */}
      <Menu.Item
        key="/conversations"
        icon={
          <Icon name="history" width={20} height={20} className="custom-menu-item" style={{ marginRight: '-2px' }} />
        }
      >
        <Link to="/conversations">{t('navigationMenu.conversationsSection.title')}</Link>
      </Menu.Item>

      {/* Stats Section */}
      <Menu.Item
        key="/stats"
        icon={
          <Icon name="reports" width={22} height={22} className="custom-menu-item" style={{ marginRight: '-4px' }} />
        }
      >
        <Link to="/stats">{t('navigationMenu.statsSection.title')}</Link>
      </Menu.Item>

      {/* Admin Section */}
      {user?.role === RolesEnum.ADMIN && (
        <Menu.Item
          key="/admin/accounts"
          icon={
            <Icon name="accounts" width={22} height={20} className="custom-menu-item" style={{ marginRight: '-4px' }} />
          }
        >
          <Link to="/admin/accounts">{t('navigationMenu.adminSection.accounts')}</Link>
        </Menu.Item>
      )}
    </Menu>
  )
}

export default NavigationMenu
