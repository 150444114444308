import React, { useEffect, useMemo } from 'react'
import TestChatButton from '../../../../../components/TestChatButton/TestChatButton'
import { useAppSelector } from '../../../../../hooks/appHook'
import {
  useDeleteStorageKnowledgeRecordByIdMutation,
  useLazyGetStorageKnowledgeRecordsByIdQuery,
  useUpdateStorageKnowledgeRecordByIdMutation,
} from '../../../../../store/api/storage.api'
import { EditStorageKnowledgeRecord, StorageKnowledgeRecord } from '../../../../../types/storage.type'
import KnowledgeRecordsList from '../../KnowledgeRecordsList/KnowledgeRecordsList'
import { useTranslation } from 'react-i18next'

const ReviewAllDataTab = () => {
  const { t } = useTranslation('knowledgeBase')

  /** Storage */
  const { currentStorageId } = useAppSelector((state) => state.storage)

  /** Storage Actions */
  const [getStorageKnowledgeRecords, getStorageKnowledgeRecordsQueryResult] =
    useLazyGetStorageKnowledgeRecordsByIdQuery()

  const [updateStorageKnowledgeRecord, updateStorageKnowledgeRecordMutationResult] =
    useUpdateStorageKnowledgeRecordByIdMutation()

  const [deleteStorageKnowledgeRecord, deleteStorageKnowledgeRecordMutationResult] =
    useDeleteStorageKnowledgeRecordByIdMutation()

  useEffect(() => {
    getStorageKnowledgeRecords({ id: currentStorageId })
  }, [currentStorageId])

  const handleDeleteItem = async (item: StorageKnowledgeRecord) => {
    await deleteStorageKnowledgeRecord({ id: currentStorageId, vectorId: item.id })
    await getStorageKnowledgeRecords({ id: currentStorageId })
  }

  const handleEditData = async ({ value, documentId, chunkIndex }: EditStorageKnowledgeRecord) => {
    await updateStorageKnowledgeRecord({
      storageId: currentStorageId,
      data: value,
      documentId,
      chunkIndex,
    })

    await getStorageKnowledgeRecords({ id: currentStorageId })
  }

  const isLoading = useMemo(() => {
    return (
      getStorageKnowledgeRecordsQueryResult.isFetching ||
      deleteStorageKnowledgeRecordMutationResult.isLoading ||
      updateStorageKnowledgeRecordMutationResult.isLoading
    )
  }, [
    getStorageKnowledgeRecordsQueryResult,
    deleteStorageKnowledgeRecordMutationResult,
    updateStorageKnowledgeRecordMutationResult,
  ])

  return (
    <div className="custom-separator-list-container">
      <div className="tab-header">
        <div className="header-description">
          <h2>{t('reviewAllDataTab.headerTitle')}</h2>
          <p>{t('reviewAllDataTab.headerDescription')}</p>
        </div>
        <TestChatButton />
      </div>
      <KnowledgeRecordsList
        records={getStorageKnowledgeRecordsQueryResult.data?.documents || []}
        loading={isLoading}
        onPressDelete={handleDeleteItem}
        onPressEdit={handleEditData}
      />
    </div>
  )
}

export default ReviewAllDataTab
