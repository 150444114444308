import { useDispatch } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { appStateActionCreators } from '../store/slices/appState.slice'
import { onboardingActionCreators } from '../store/slices/onboardingSlice'

export const useOnboardingActions = () => {
  const dispatch = useDispatch()
  return bindActionCreators(onboardingActionCreators, dispatch)
}

export const useAppStateActions = () => {
  const dispatch = useDispatch()
  return bindActionCreators(appStateActionCreators, dispatch)
}
