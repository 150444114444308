import React, { useState } from 'react'
// https://github.com/openai/openai-realtime-api-beta/pull/11 temporary use patched version.
// TODO: remove after PR are accepted by OpenAI
import { AudioFilled } from '@ant-design/icons'
import './openaiRealtime.less'
import { Conversation } from '../../../types/conversation.type'
import OpenaiRealtimeEnabled from './OpenaiRealtimeEnabled'

/**
 * Type for all event logs
 */

type IProps = {
  conversationId: string
  setIsRealtimeVisible: (visible: boolean) => void
  isRealtimeVisible: boolean
  refetchMessages: () => void
  createConversation: () => Promise<Conversation | undefined>
}

const OpenaiRealtimeButton = ({
  conversationId,
  setIsRealtimeVisible,
  isRealtimeVisible,
  refetchMessages,
  createConversation,
}: IProps) => {
  const [newConversationId, setNewConversationId] = useState<string | null>(null)

  const onEnableRealtime = async () => {
    if (!conversationId) {
      const createdConversation = await createConversation()
      if (createdConversation?.id) {
        setNewConversationId(createdConversation.id)
      }
      if (!createdConversation?.storageId) {
        return
      }
    }
    setIsRealtimeVisible(true)
  }

  return (
    <>
      <AudioFilled
        className={isRealtimeVisible ? 'disconnect icon' : 'connect icon'}
        onClick={() => (isRealtimeVisible ? setIsRealtimeVisible(false) : onEnableRealtime())}
      />
      {isRealtimeVisible && (
        <OpenaiRealtimeEnabled
          conversationId={conversationId || newConversationId || ''}
          refetchMessages={refetchMessages}
          disableRealtime={() => setIsRealtimeVisible(false)}
        />
      )}
    </>
  )
}

export default OpenaiRealtimeButton
