import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetStorageListQuery } from '../../../../store/api/storage.api'
import { setCurrentStorageId } from '../../../../store/slices/storage.slice'
import CustomSelect from '../../../../components/CustomSelect/CustomSelect'
import AiHelperHeader from '../../../../components/AIHelperHeader/aiHelperHeader'
import CreateNewStorageModal from '../forms/CreateStorageForm/components/modals/CreateNewStorageModal/CreateNewStorageModal'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useAppStateActions } from '../../../../hooks/actions'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../hooks/appHook'

const QUERY_STORAGE_ID_NAME = 'storageId'

const KnowledgeBasePageHeader = ({ title }: { title: string }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation('knowledgeBase')
  const { currentStorageId } = useAppSelector((state) => state.storage)

  const { setIsCreateStorageModalOpen } = useAppStateActions()

  const { data: storages, refetch } = useGetStorageListQuery()

  const queryParams = new URLSearchParams(location.search)

  const firstStorageInStorages = useMemo(() => {
    return storages?.[0] || null
  }, [storages])

  const selectOptions = useMemo(() => {
    return storages?.map(({ id, name }) => ({ value: id, label: name })) || []
  }, [storages])

  const checkIsStorageAvailable = async (storageId: string) => {
    const result = await refetch()
    if ('data' in result) {
      return result.data?.some((storage) => storage.id === storageId)
    }
  }

  useEffect(() => {
    const handleStorageAndQueryParams = async () => {
      if (!storages || storages.length === 0) {
        return
      }

      if (!currentStorageId && firstStorageInStorages) {
        dispatch(setCurrentStorageId(firstStorageInStorages.id))
        return
      }

      const isStorageAvailable = await checkIsStorageAvailable(currentStorageId)
      if (!isStorageAvailable) {
        dispatch(setCurrentStorageId(firstStorageInStorages?.id || ''))
        return
      }

      const existingQueryId = queryParams.get(QUERY_STORAGE_ID_NAME)
      if (existingQueryId !== currentStorageId) {
        queryParams.set(QUERY_STORAGE_ID_NAME, currentStorageId)
        navigate({ search: queryParams.toString() }, { replace: true })
      }
    }

    handleStorageAndQueryParams().catch((error) => console.error('Error handling storage or query params:', error))
  }, [storages, currentStorageId, firstStorageInStorages])

  const onChangeSelect = useCallback(
    (value: string) => {
      dispatch(setCurrentStorageId(value))
    },
    [location.search, navigate, dispatch],
  )

  const onPressCreteButton = useCallback(() => {
    setIsCreateStorageModalOpen(true)
  }, [])

  return (
    <div className="page-header">
      <CreateNewStorageModal />
      <AiHelperHeader title={title} paragraph="" />
      <div className="header-right-content">
        {storages?.length ? (
          <CustomSelect
            createButtonTitle={t('knowledgeBasePageHeader.addNewData')}
            onChange={onChangeSelect}
            options={selectOptions}
            initialStatus={currentStorageId}
            key={currentStorageId}
            onPressCreteButton={onPressCreteButton}
          />
        ) : (
          <Button type="text" icon={<PlusOutlined />} onClick={onPressCreteButton} className="create-storage-button">
            {t('knowledgeBasePageHeader.noStorageMessage')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default memo(KnowledgeBasePageHeader)
