import React, { memo, useCallback } from 'react'
import { shallowEqual } from 'react-redux'
import { CompanySizeOption } from '../../../../../types/onboarding.type'
import { companySizeOptions } from '../../../../../store/slices/onboardingSlice'
import { useOnboardingActions } from '../../../../../hooks/actions'
import { useAppSelector } from '../../../../../hooks/appHook'
import SelectButton from '../../SelectButton/SelectButton'

const SecondStep: React.FC = () => {
  /** Storage */
  const companySize =
    useAppSelector((state) => state.onboarding.companySize, shallowEqual);

  /** Use custom hooks */
  const {
    setCompanySize
  } = useOnboardingActions();

  const handleClick = useCallback((option: CompanySizeOption) => {
    setCompanySize(option);
  }, [setCompanySize]);

  return (
    <div className="poll-content-cards-wrapper">
      {companySizeOptions.map((option: CompanySizeOption) => (
        <SelectButton<CompanySizeOption>
          key={option}
          isSelected={option === companySize}
          option={option}
          handleClick={handleClick}
          data-testid="second-step"
        />
      ))}
    </div>
  );
};

export default memo(SecondStep)
