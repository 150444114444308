import React from 'react'
import { Spin, SpinProps } from 'antd'

type IProps = {
  isLoading?: boolean
  fullScreenHeight?: boolean
  size?: SpinProps['size']
}

const Loader = ({ isLoading = true, fullScreenHeight = false, size = 'large' }: IProps) => {
  return (
    <div
      style={{
        width: '100%',
        height: fullScreenHeight ? '100dvh' : '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin size={size} spinning={isLoading} />
    </div>
  )
}

export default Loader
