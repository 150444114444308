export enum KnowledgeBaseRoutes {
  addData = 'add_data',
  documents = 'documents',
  searchAndEdit = 'search_and_edit',
  review = 'review',
  settings = 'settings',
}

export enum AddNewDataTabRoutes {
  addHtmlOrText = 'add_html_or_text',
  addDocument = 'add_document',
  addDataByUrl = 'add_data_by_url',
  addDocumentByUrl = 'add_document_by_url',
}
