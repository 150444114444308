import React, { memo, useCallback, useState } from 'react'
import { Button, notification, Popover } from 'antd'
import { useAppStateActions } from '../../../hooks/actions'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import Icon from '../../UI/Icons/Icons'

interface IProps {
  isFullSelectedImages: boolean
  disabled: boolean
  onDrop: (files: Array<File>) => void
}

const AddImagesPopover = ({ isFullSelectedImages, onDrop, disabled }: IProps) => {
  const { t } = useTranslation('components')
  const dispatch = useDispatch()

  /** State */
  const [isOpenPopover, setIsOpenPopover] = useState(false)

  const onDropRejected = () => {
    notification.error({
      message: 'Maximum file size is 20 MB',
    })
  }

  /** Use custom hooks */
  const { setAddImageByUrlModalOpen } = useAppStateActions()
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/gif': [],
    },
    maxSize: 20 * 1024 * 1024,
  })

  const onClickSelectByLinkImage = useCallback(() => {
    dispatch(setAddImageByUrlModalOpen(true))
    setIsOpenPopover(false)
  }, [])

  const onClickSelectFromFSImage = useCallback(() => {
    setIsOpenPopover(false)
  }, [])

  return (
    <Popover
      open={isOpenPopover && !isFullSelectedImages}
      content={
        <div className="attach-image-popover">
          <Button block className="popover-button attach-image-by-link-button" onClick={onClickSelectByLinkImage}>
            {t('chat.addImagesPopover.addByLink')}
          </Button>
          <div className="upload-dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <Button className="popover-button attach-image-by-fs-button" onClick={onClickSelectFromFSImage}>
              {t('chat.addImagesPopover.addFromFileSystem')}
            </Button>
          </div>
        </div>
      }
      trigger="click"
      onOpenChange={(newVisible) => setIsOpenPopover(newVisible)}
    >
      <Button
        style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', padding: 0 }}
        className={`attach-images-button ${isFullSelectedImages && 'attach-images-button-disabled'}`}
        disabled={isFullSelectedImages || disabled}
        icon={<Icon name="paperClip" width={12} height={22} />}
        onClick={() => setIsOpenPopover(true)}
      ></Button>
    </Popover>
  )
}

export default memo(AddImagesPopover)
