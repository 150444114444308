import React, { useCallback, useEffect } from 'react'
import { Form, Modal } from 'antd'
import { useAppSelector } from '../../../../hooks/appHook'
import { useAppStateActions } from '../../../../hooks/actions'
import { useSuccessMessage } from '../../../../hooks/useSuccessMessage'
import AppPrimaryButton from '../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import Icon from '../../../../components/UI/Icons/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCreateAgentMutation, useGetAgentListQuery } from '../../../../store/api/agent.api'
import CEAIAgentForm from '../CEAIAgentForm/CEAIAgentForm'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setCurrentAgentId } from '../../../../store/slices/agents.slice'

const CreateNewAIAgentModal = () => {
  const { t } = useTranslation('aiAgent')
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { setIsCreateAIAgentModalOpen } = useAppStateActions()

  const [createAIAgentForm] = Form.useForm()
  const { isCreateAIAgentModalOpen } = useAppSelector((state) => state.appState.modals)

  const [createAIAgent, { isSuccess, isLoading, data }] = useCreateAgentMutation()
  const { refetch } = useGetAgentListQuery()

  useSuccessMessage(t('aiAgentPageHeader.createNewAIAgentModal.messages.agentCreated'), isLoading)

  const onCompleteFormCreateAIAgent = useCallback((values: { name: string }) => {
    createAIAgent({ body: values }).then(async (data) => {
      if ('data' in data) {
        await refetch()

        setTimeout(() => {
          dispatch(setCurrentAgentId(data?.data.id))
        }, 100)
      }
    })
  }, [])

  const onCancelFormCreateStorage = useCallback(() => {
    setIsCreateAIAgentModalOpen(false)
    createAIAgentForm.resetFields()
  }, [])

  useEffect(() => {
    if (isSuccess) {
      setIsCreateAIAgentModalOpen(false)
      createAIAgentForm.resetFields()
    }
  }, [isSuccess])

  useEffect(() => {
    if (data?.id) {
      const queryParams = new URLSearchParams(location.search)
      queryParams.set('agentId', data.id)
      navigate({ search: queryParams.toString() }, { replace: true })
    }
  }, [data])

  return (
    <Modal
      title={t('aiAgentPageHeader.createNewAIAgentModal.title')}
      open={isCreateAIAgentModalOpen}
      footer={
        <AppPrimaryButton
          htmlType="submit"
          id="app-primary-button"
          loading={isLoading}
          onClick={createAIAgentForm.submit}
        >
          {t('aiAgentPageHeader.createNewAIAgentModal.createButton')}
          <Icon name="buttonRight" />
        </AppPrimaryButton>
      }
      onCancel={onCancelFormCreateStorage}
    >
      <div>
        <CEAIAgentForm aiAgentForm={createAIAgentForm} onSubmitForm={onCompleteFormCreateAIAgent} />
      </div>
    </Modal>
  )
}

export default CreateNewAIAgentModal
