import { useRef } from 'react'

const useScrollToMessage = () => {
  const messageIdRef = useRef<string | null>(null)

  const saveMessageId = (id: string) => {
    messageIdRef.current = id
  }

  const scrollToMessage = () => {
    if (messageIdRef.current) {
      const element = document.getElementById(messageIdRef.current)
      if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'center' })
      }
    }
  }

  return { saveMessageId, scrollToMessage }
}

export default useScrollToMessage
