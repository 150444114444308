import React, { useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import './conversationTextFeedback.less'
import AppPrimaryButton from '../../UI/AppPrimaryButton/AppPrimaryButton'
import { CaretDownOutlined, CaretUpOutlined, DislikeOutlined, LikeOutlined } from '@ant-design/icons'
import { useEditConversationMutation } from '../../../store/api/pubic.api'
import { useTranslation } from 'react-i18next'
import { useGetConversationByIdQuery } from '../../../store/api/conversation.api'

type IProps = {
  conversationId: string
}

type Rating = 'like' | 'dislike'

const ConversationTextFeedback = ({ conversationId }: IProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [rating, setRating] = useState<Rating | null>(null)

  const conversationT = useTranslation('conversations')

  //* API */
  const [sendConversationFeedback, sendConversationFeedbackResult] = useEditConversationMutation()

  const { refetch } = useGetConversationByIdQuery({ id: conversationId || '' })

  //* HANDLERS */
  const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackMessage(e.target.value)
  }

  const onSubmit = () => {
    if (rating && feedbackMessage) {
      sendConversationFeedback({
        id: conversationId,
        body: { conversationFeedback: { feedback: feedbackMessage, rating } },
      })
    }
  }

  //* useEffect */
  useEffect(() => {
    if (sendConversationFeedbackResult.isSuccess) {
      setFeedbackMessage('')
      refetch()
    }
  }, [sendConversationFeedbackResult.isSuccess])

  return (
    <Form
      className={isOpen ? 'conversation-text-feedback feedback-form-open' : 'conversation-text-feedback'}
      onFinish={onSubmit}
    >
      <div className="feedback-header">
        <h4 className="title" onClick={() => setIsOpen((v) => !v)}>
          {conversationT.t('conversation.conversationTextFeedback.title')}
          {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </h4>
      </div>

      {isOpen && (
        <>
          <div className="rating-wrapper">
            <LikeOutlined
              className={rating === 'like' ? 'rating-icon selected' : 'rating-icon'}
              onClick={() => setRating('like')}
            />
            <DislikeOutlined
              className={rating === 'dislike' ? 'rating-icon selected' : 'rating-icon'}
              onClick={() => setRating('dislike')}
            />
          </div>
          <Input.TextArea
            placeholder={conversationT.t('conversation.conversationTextFeedback.placeholder')}
            value={feedbackMessage}
            onChange={handleFeedbackChange}
            rows={4}
            style={{ marginBottom: '10px', resize: 'none', padding: '10px', borderRadius: '15px' }}
            required
          />

          <AppPrimaryButton
            type="primary"
            htmlType="submit"
            className="submit-button"
            size="middle"
            disabled={!feedbackMessage || !rating}
            loading={sendConversationFeedbackResult.isLoading}
          >
            {conversationT.t('conversation.conversationTextFeedback.submit')}
          </AppPrimaryButton>
        </>
      )}
    </Form>
  )
}

export default ConversationTextFeedback
