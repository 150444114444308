import React from 'react'
import { useGetConversationStatsQuery } from '../../store/api/conversation.api'
import { Spin } from 'antd'
import Statistics from './components/Statistics/Statistics'
import './reports.less'
import { useTranslation } from 'react-i18next'
import AiHelperHeader from '../../components/AIHelperHeader/aiHelperHeader'

const Reports = () => {
  const { t } = useTranslation('reports')

  const { data, isLoading } = useGetConversationStatsQuery()

  if (isLoading) {
    return <Spin size={'large'} />
  }

  return (
    <>
      <div className="left-header-item">
        <AiHelperHeader paragraph={t('performanceMessage')} title={t('title')} />
      </div>
      <Statistics data={data || {}} />
    </>
  )
}

export default Reports
