import { useState, useEffect } from 'react'

const useWindowSize = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isDesktop, setIsDesktop] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      const lgBreakpoint = 992 // Ant Design breakpoint for lg

      setIsMobile(width < lgBreakpoint)
      setIsDesktop(width >= lgBreakpoint)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { isMobile, isDesktop }
}

export default useWindowSize
