import React, { useEffect } from 'react'
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons'
import { useEditConversationMutation } from '../../../store/api/pubic.api'
import './LikeDislikeConversationButtons.less'
import { ConversationFeedback } from '../../../types/conversation.type'
import { useAppSelector } from '../../../hooks/appHook'
import { useGetConversationByIdQuery } from '../../../store/api/conversation.api'

type IProps = {
  conversationId: string
  feedback: ConversationFeedback[] | undefined
  isTextFeedbackAllowed: boolean
}

const LikeDislikeConversationButtons = ({ conversationId, feedback, isTextFeedbackAllowed }: IProps) => {
  const { user } = useAppSelector((state) => state.appState)

  const { refetch } = useGetConversationByIdQuery({ id: conversationId || '' })
  const [sendConversationFeedback, sendConversationFeedbackResult] = useEditConversationMutation()

  const myFeedback = feedback?.find((f) => f.userId === user.id && !f.feedback)

  const onClick = (rating: 'like' | 'dislike') => {
    sendConversationFeedback({ id: conversationId, body: { conversationFeedback: { rating } } })
  }

  useEffect(() => {
    if (sendConversationFeedbackResult.isSuccess) {
      refetch()
    }
  }, [sendConversationFeedbackResult.isSuccess])

  return (
    <div className="like-dislike-conversation-buttons">
      {myFeedback ? (
        <>
          <DislikeOutlined className={`icon ${myFeedback?.rating === 'dislike' ? 'selected' : 'hidden'}`} />
          <LikeOutlined className={`icon ${myFeedback?.rating === 'like' ? 'selected' : 'hidden'}`} />
        </>
      ) : (
        !isTextFeedbackAllowed && (
          <>
            <DislikeOutlined className={`icon`} onClick={() => onClick('dislike')} />
            <LikeOutlined className={`icon`} onClick={() => onClick('like')} />
          </>
        )
      )}
    </div>
  )
}

export default LikeDislikeConversationButtons
