import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../../hooks/appHook'
import KnowledgeBaseDocumentsList from '../../KnowledgeBaseDocumentsList/KnowledgeBaseDocumentsList'
import { useDeleteDocumentMutation, useGetDocumentsQuery } from '../../../../../store/api/document.api'
import { notification } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import AddDocumentButton from '../../../../../components/AddDocumentButton/AddDocumentButton'
import { IPaginationParams } from '../../../../../types/pagination-params.type'
import { useTranslation } from 'react-i18next'

const DocumentsTab = () => {
  const { t } = useTranslation('knowledgeBase')
  const [pagination, setPagination] = useState<IPaginationParams>({
    page: 1,
    pageSize: 10,
  })

  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { currentStorageId } = useAppSelector((state) => state.storage)
  const { data, isLoading, refetch } = useGetDocumentsQuery({ storageId: currentStorageId, pagination })
  const [deleteDocument] = useDeleteDocumentMutation()

  const onDeleteDocument = async (documentId: string) => {
    try {
      await deleteDocument({ documentId })
      queryParams.delete('documentId')
      navigate({ pathname: location.pathname, search: queryParams.toString() })
    } catch (e: any) {
      notification.error({ message: t('documentsTab.errorMessage') })
    }
  }

  useEffect(() => {
    refetch()
  }, [pagination])

  return (
    <div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h2>{t('documentsTab.headerTitle')}</h2>
          <AddDocumentButton />
        </div>
      </div>
      <KnowledgeBaseDocumentsList
        documents={data?.documents || []}
        onDelete={onDeleteDocument}
        isLoading={isLoading}
        pagination={pagination}
        setPagination={(p: IPaginationParams) => setPagination(p)}
        count={data?.count || 0}
      />
    </div>
  )
}

export default DocumentsTab
