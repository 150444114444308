import { LocalStorage } from 'ts-localstorage'
import { createApi } from '@reduxjs/toolkit/query/react'
import { IAccount } from '../../types/account.type'
import { IUser } from '../../types/profile.type'
import { ACCESS_TOKEN } from '../../config/consts'
import baseQueryWithReauth from '../baseQueryWithReauth'

export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Account'],
  endpoints: (builder) => ({
    getAccount: builder.query<{ account: IAccount; user: IUser }, void | string>({
      query: () => ({
        url: `accounts`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
      providesTags: ['Account'],
    }),
    accountUpdate: builder.mutation<IAccount, Partial<IAccount>>({
      query: (args) => ({
        url: `accounts`,
        method: 'PATCH',
        credentials: 'include',
        mode: 'cors',
        body: args,
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
      invalidatesTags: ['Account'],
    }),
    deleteAccount: builder.mutation<boolean | { message: string }, string>({
      query: (accountId) => ({
        url: `/accounts/${accountId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const { useGetAccountQuery, useAccountUpdateMutation, useLazyGetAccountQuery, useDeleteAccountMutation } =
  accountApi
