import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { AccountStatusEnum } from '../../types/account.type'
import { ONBOARDING_ROUTE } from '../../config/consts'
import { useAppSelector } from '../../hooks/appHook'
import { useGetAccountQuery } from '../../store/api/account.api'
import './guestLayout.less'
import TopFloater from '../../routes/guest/TopFloater'

const GuestLayout = () => {
  const navigate = useNavigate()
  const { user } = useAppSelector((state) => state.appState)
  const { data } = useGetAccountQuery(undefined, { skip: !user.id })

  useEffect(() => {
    if (isEmpty(data)) {
      return
    }

    const isOnboarding = data?.account.status === AccountStatusEnum.ONBOARD
    const isVerified = data?.user.status === 'verified'

    if (isOnboarding && location.pathname !== ONBOARDING_ROUTE && isVerified) {
      return navigate(ONBOARDING_ROUTE)
    }
  }, [data])

  return (
    <div className="guest-page">
      <div className="guest-page-bg-shape" />
      <div className="guest-page-bg-shape" />
      <div className="guest-page-bg-shape" />
      <div className="guest-page-bg-shape" />
      <TopFloater />
      <Outlet />
    </div>
  )
}

export default GuestLayout
