import React, { memo, useCallback } from 'react'
import { shallowEqual } from 'react-redux'
import { useOnboardingActions } from '../../../../../hooks/actions'
import { CompanyCaseOption } from '../../../../../types/onboarding.type'
import { companyCaseOptions } from '../../../../../store/slices/onboardingSlice'
import { useAppSelector } from '../../../../../hooks/appHook'
import SelectButton from '../../SelectButton/SelectButton'
const ThirdStep: React.FC = () => {
  /** Storage */
  const useCase =
    useAppSelector((state) => state.onboarding.useCase, shallowEqual);

  /** Use custom hooks */
  const {
    setCompanyCase
  } = useOnboardingActions();

  const handleClick = useCallback((option: CompanyCaseOption) => {
    setCompanyCase(option);
  }, [setCompanyCase]);

  return (
    <div className="poll-content-cards-wrapper">
      {companyCaseOptions.map((option: CompanyCaseOption) => (
        <SelectButton<CompanyCaseOption>
          key={option}
          isSelected={option === useCase}
          option={option}
          handleClick={handleClick}
          data-testid="third-step"
        />
      ))}
    </div>
  );
}

export default memo(ThirdStep)
