import React from 'react'
import '../../layouts/guestLayout/guestLayout.less'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation('guest')
  return <div className="login-box">{t('notFound.message')}</div>
}

export default NotFound
