import { Form, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Icon from '../../components/UI/Icons/Icons'
import { useAppConfig, useAppSelector } from '../../hooks/appHook'
import { PROFILE_EDIT_ROUTE } from '../../config/consts'
import { useSuccessMessage } from '../../hooks/useSuccessMessage'
import { useInviteCreateSessionMutation } from '../../store/api/users.api'
import { useInviteSessionAndSetPasswordMutation } from '../../store/api/profile.api'
import AppAuthInputPassword from '../../components/UI/Input/AppAuthInputPassword'
import AppPrimaryButton from '../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/UI/Loader/Loader'

const InviteCreateSession = () => {
  const { appName } = useAppConfig()
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const email = params.get('email')
  const [initialError, setInitialError] = useState<string>('')
  const { user } = useAppSelector((state) => state.appState)

  const [inviteCreateSession, { status: inviteStatus }] = useInviteCreateSessionMutation()
  const [setPassword, { status: userUpdateStatus }] = useInviteSessionAndSetPasswordMutation()

  const { t } = useTranslation('guest')

  useSuccessMessage(t('inviteCreateSession.successMessage'), userUpdateStatus === 'fulfilled')

  const onFinishPasswordChange = (values: { password: string; confirmPassword: string }) => {
    setPassword({ ...values, id: user.id, passwordChange: true })
  }

  useEffect(() => {
    if (email && code) {
      inviteCreateSession({ email, code })
    } else {
      setInitialError(t('inviteCreateSession.errorMessage.invalidEmailOrCode'))
    }
  }, [email, code])

  useEffect(() => {
    if (userUpdateStatus === 'fulfilled') {
      navigate(PROFILE_EDIT_ROUTE)
    }
  }, [userUpdateStatus])

  if (inviteStatus === 'pending') {
    return <Loader fullScreenHeight />
  }

  if (initialError) {
    return <div></div>
  }

  return (
    <div className="login-box">
      <Form name="login-form" onFinish={onFinishPasswordChange}>
        <p className="form-title">{t('inviteCreateSession.header', { name: appName })}</p>
        <p>{t('inviteCreateSession.instruction')}</p>

        <Form.Item
          name="password"
          rules={[{ required: true, min: 8, message: t('inviteCreateSession.errorMessage.passwordShort') }]}
          hasFeedback
        >
          <AppAuthInputPassword placeholder="Password" />
        </Form.Item>

        <Form.Item
          hasFeedback
          name="confirmPassword"
          dependencies={['password']}
          className="invite-create-session-form-item-confirm-password"
          rules={[
            ({ getFieldValue }) => ({
              required: true,
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t('inviteCreateSession.errorMessage.passwordMismatch')))
              },
            }),
          ]}
        >
          <AppAuthInputPassword placeholder={t('inviteCreateSession.confirmPassword')} />
        </Form.Item>
        <Form.Item>
          <Space>
            <AppPrimaryButton size="large" type="primary" htmlType="submit" loading={userUpdateStatus === 'pending'}>
              {t('inviteCreateSession.setPassword')}
              <Icon name={'buttonRight'} />
            </AppPrimaryButton>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default InviteCreateSession
