import React, { useEffect } from 'react'
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons'
import { useEditConversationMutation } from '../../../store/api/pubic.api'
import { useAppSelector } from '../../../hooks/appHook'
import './LikeDislikeMessageButtons.less'
import { MessageFeedback } from '../../../types/storage.type'
import useScrollToMessage from '../../../hooks/useScrollToMessage'

type IProps = {
  feedback: [MessageFeedback] | undefined
  messageId: string | number
  conversationId: string
  refetchConversation: () => void
}

const LikeDislikeMessageButtons = ({ feedback, messageId, conversationId, refetchConversation }: IProps) => {
  const [rateMessage, rateMessageResult] = useEditConversationMutation()
  const { user } = useAppSelector((state) => state.appState)

  const { saveMessageId, scrollToMessage } = useScrollToMessage()
  const myFeedback = feedback?.find((f) => f.userId === user.id)

  const onClick = (rating: 'like' | 'dislike') => {
    saveMessageId(String(messageId))
    rateMessage({
      id: conversationId,
      body: {
        messageFeedback: {
          messageId,
          rating,
        },
      },
    })
  }

  useEffect(() => {
    if (rateMessageResult.isSuccess) {
      refetchConversation()
      setTimeout(() => {
        scrollToMessage()
      }, 500)
    }
  }, [rateMessageResult.isSuccess])

  return (
    <div className="like-message-buttons-wrapper">
      {myFeedback ? (
        myFeedback.rating === 'like' ? (
          <LikeOutlined style={{ color: 'gray' }} />
        ) : (
          <DislikeOutlined style={{ color: 'gray' }} />
        )
      ) : (
        <>
          <LikeOutlined onClick={() => onClick('like')} />
          <DislikeOutlined onClick={() => onClick('dislike')} />
        </>
      )}
    </div>
  )
}

export default LikeDislikeMessageButtons
