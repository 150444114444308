import React from 'react'
import { Form, Input, notification } from 'antd'
import AppPrimaryButton from '../../../../../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useAppSelector } from '../../../../../../../../hooks/appHook'
import { requiredValidator } from '../../../../../../../../helpers/validators'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCreateDocumentMutation } from '../../../../../../../../store/api/document.api'
import { useTranslation } from 'react-i18next'
import { KnowledgeBaseRoutes } from '../../../../../../../../types/enums/knowledge-base-routes.enum'

const AddFileByUrlTab = () => {
  const { t } = useTranslation('knowledgeBase')
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm()

  /** Storage */
  const { currentStorageId } = useAppSelector((state) => state.storage)

  /** API */
  const [createDocument, createDocumentMutation] = useCreateDocumentMutation()

  const onSubmit = async (values: { query: string }) => {
    try {
      const searchParams = new URLSearchParams(location.search)
      const createdDocument = await createDocument({
        storageId: currentStorageId,
        url: values.query,
      }).unwrap()

      if (createdDocument.id) {
        notification.success({ message: t('addNewDataTab.addFileByUrlTab.successMessage') })
        searchParams.set('documentId', createdDocument.id)
        navigate(`/storage/${KnowledgeBaseRoutes.review}?${searchParams.toString()}`)
      }
    } catch (e: any) {
      notification.error({ message: t('addNewDataTab.addFileByUrlTab.errorMessage') })
    }
  }

  return (
    <div className="add-by-url-tab">
      <h3>{t('addNewDataTab.addFileByUrlTab.headerTitle')}</h3>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          name="query"
          rules={[{ required: true, validator: requiredValidator(t('addNewDataTab.addFileByUrlTab.linkRequired')) }]}
        >
          <Input size="large" />
        </Form.Item>
        <div className="search-buttons-container">
          <Form.Item>
            <AppPrimaryButton
              type="primary"
              htmlType="submit"
              className="app-primary-button-medium"
              size="middle"
              loading={createDocumentMutation.isLoading}
            >
              {t('addNewDataTab.addFileByUrlTab.indexUrl')}
            </AppPrimaryButton>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default AddFileByUrlTab
