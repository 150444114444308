import React from 'react'
import TransparentButton from '../../components/UI/TransparentButton/TransparentButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AddNewDataTabRoutes, KnowledgeBaseRoutes } from '../../types/enums/knowledge-base-routes.enum'

const AddDocumentButton = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('components')
  const onClick = () => {
    navigate('/storage/' + KnowledgeBaseRoutes.addData + '/' + AddNewDataTabRoutes.addDocument)
  }
  return <TransparentButton onClick={onClick} title={t('addDocumentButton.title')} />
}

export default AddDocumentButton
