import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../baseQueryWithReauth'
import { ConversationImageUrl } from '../../types/conversation.type'

export const uploadsApi = createApi({
  reducerPath: 'uploadsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    uploadFile: builder.mutation<ConversationImageUrl, { body: FormData }>({
      query: ({ body }) => ({
        url: `uploads`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useUploadFileMutation,
} = uploadsApi
