import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Input } from 'antd'
import AppPrimaryButton from '../../../../../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useSuccessMessage } from '../../../../../../../../hooks/useSuccessMessage'
import { useWarningMessage } from '../../../../../../../../hooks/useWarningMessage'
import { useAppSelector } from '../../../../../../../../hooks/appHook'
import {
  useCreateStorageKnowledgeRecordByUrlMutation,
  useDeleteStorageKnowledgeRecordByIdMutation,
  useUpdateStorageKnowledgeRecordByIdMutation,
} from '../../../../../../../../store/api/storage.api'
import {
  setAddedKnowledgeRecords,
  setEditedKnowledgeRecordId,
  setEditedKnowledgeRecords,
  setKnowledgeRecords,
  setIsSuccess,
} from '../../../../../../../../store/slices/storage.slice'
import { EditStorageKnowledgeRecord, StorageKnowledgeRecord } from '../../../../../../../../types/storage.type'
import KnowledgeRecordsList from '../../../../../KnowledgeRecordsList/KnowledgeRecordsList'
import { useTranslation } from 'react-i18next'

const AddByUrlTab = () => {
  const { t } = useTranslation('knowledgeBase')
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  /** Storage */
  const { storageKnowledgeRecords, editedKnowledgeRecordId, addedKnowledgeRecords, currentStorageId } = useAppSelector(
    (state) => state.storage,
  )

  /** Storage Actions */
  const [createStorageKnowledgeRecordByUrl, createStorageKnowledgeRecordByUrlMutationResult] =
    useCreateStorageKnowledgeRecordByUrlMutation()

  const [updateStorageKnowledgeRecord, updateStorageKnowledgeRecordMutationResult] =
    useUpdateStorageKnowledgeRecordByIdMutation()

  const [deleteStorageKnowledgeRecord, deleteAiDataMutationResult] = useDeleteStorageKnowledgeRecordByIdMutation()

  /** Response message handlers */

  useSuccessMessage(
    t('addNewDataTab.addByUrlTab.successMessage'),
    createStorageKnowledgeRecordByUrlMutationResult.isSuccess &&
      !!createStorageKnowledgeRecordByUrlMutationResult.data?.documents?.length,
  )
  useWarningMessage(
    t('addNewDataTab.addByUrlTab.warningMessage'),
    createStorageKnowledgeRecordByUrlMutationResult.isSuccess &&
      !createStorageKnowledgeRecordByUrlMutationResult.data?.documents?.length,
  )

  const onCreateStorageKnowledgeRecordByUrl = (values: { query: string }) => {
    createStorageKnowledgeRecordByUrl({
      id: currentStorageId,
      body: {
        url: values.query,
      },
    })
  }

  const handleDeleteItem = async (item: StorageKnowledgeRecord) => {
    await deleteStorageKnowledgeRecord({ id: currentStorageId, vectorId: item.id })
    const filteredDataSource = addedKnowledgeRecords?.filter((record) => record.id !== item.id)

    filteredDataSource && dispatch(setKnowledgeRecords(filteredDataSource))
  }

  const handleEditData = async ({ value, recordId, documentId, chunkIndex }: EditStorageKnowledgeRecord) => {
    await updateStorageKnowledgeRecord({
      storageId: currentStorageId,
      data: value,
      documentId,
      chunkIndex,
    })

    dispatch(setEditedKnowledgeRecordId(recordId))
  }

  useEffect(() => {
    if (editedKnowledgeRecordId && storageKnowledgeRecords[0]) {
      const index = addedKnowledgeRecords?.findIndex((record) => record.id === editedKnowledgeRecordId)

      if (typeof index === 'number' && index > -1 && addedKnowledgeRecords) {
        const updatedData = [...addedKnowledgeRecords]
        updatedData[index] = storageKnowledgeRecords[0]
        dispatch(setAddedKnowledgeRecords(updatedData))
      }
    }
  }, [editedKnowledgeRecordId, storageKnowledgeRecords])

  useEffect(() => {
    return () => {
      dispatch(setKnowledgeRecords([]))
      dispatch(setEditedKnowledgeRecords([]))
      dispatch(setEditedKnowledgeRecordId(''))
      dispatch(setAddedKnowledgeRecords([]))
      dispatch(setIsSuccess(false))
      form.resetFields()
    }
  }, [])

  return (
    <div className="add-by-url-tab">
      <h3>{t('addNewDataTab.addByUrlTab.headerTitle')}</h3>
      <Form form={form} onFinish={onCreateStorageKnowledgeRecordByUrl}>
        <Form.Item name="query" rules={[{ required: true, message: t('addNewDataTab.addByUrlTab.linkRequired') }]}>
          <Input size="large" />
        </Form.Item>
        <div className="search-buttons-container">
          <Form.Item>
            <AppPrimaryButton type="primary" htmlType="submit" className="app-primary-button-medium">
              {t('addNewDataTab.addByUrlTab.indexUrl')}
            </AppPrimaryButton>
          </Form.Item>
        </div>
        <div className="custom-separator-list-container">
          <KnowledgeRecordsList
            records={addedKnowledgeRecords}
            loading={
              updateStorageKnowledgeRecordMutationResult.isLoading ||
              deleteAiDataMutationResult.isLoading ||
              createStorageKnowledgeRecordByUrlMutationResult.isLoading
            }
            onPressDelete={handleDeleteItem}
            onPressEdit={handleEditData}
          />
        </div>
      </Form>
    </div>
  )
}

export default AddByUrlTab
