import React from 'react'
import { Form, Modal } from 'antd'
import { useAppConfig } from '../../../hooks/appHook'
import { useTranslation } from 'react-i18next'
import { CreateAccountMutationBody } from '../../../store/api/admin/accounts.api'
import Icons from '../../../components/UI/Icons/Icons'
import AppPrimaryButton from '../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import AppAuthInput from '../../../components/UI/Input/AppAuthInput'

interface Props {
  isAddCompanyModalOpen: boolean
  setIsAddCompanyModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  createAccountSubmit: (values: CreateAccountMutationBody) => void
}

const InviteCompanyModal = ({
  isAddCompanyModalOpen,
  setIsAddCompanyModalOpen,
  isLoading,
  createAccountSubmit,
}: Props) => {
  const [form] = Form.useForm()
  const { appName } = useAppConfig()

  const { t } = useTranslation('accounts')

  return (
    <Modal
      open={isAddCompanyModalOpen}
      footer={
        <AppPrimaryButton htmlType="submit" id="app-primary-button" loading={isLoading} onClick={form.submit}>
          {t('createModal.submitButton')}
          <Icons name="buttonRight" />
        </AppPrimaryButton>
      }
      onCancel={() => {
        setIsAddCompanyModalOpen(false)
        form.resetFields()
      }}
    >
      <div>
        <h2>{t('createModal.title')}</h2>
        <p>{t('createModal.description', { name: appName })}</p>
        <Form
          name="normal_login"
          form={form}
          className="login-form"
          style={{ padding: '20px 0' }}
          onFinish={createAccountSubmit}
        >
          <Form.Item name="accountName" rules={[{ required: true }]}>
            <AppAuthInput
              prefix={<Icons name={'infoCircle'} />}
              placeholder={t('createModal.accountNamePlaceholder')}
            />
          </Form.Item>
          <Form.Item name="name" rules={[{ required: true }]}>
            <AppAuthInput
              prefix={<Icons name={'inputProfileCircle'} />}
              placeholder={t('createModal.namePlaceholder')}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ type: 'email', message: t('createModal.incorrectEmail') }, { required: true }]}
          >
            <AppAuthInput prefix={<Icons name={'inputSms'} />} placeholder={t('createModal.emailPlaceholder')} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default InviteCompanyModal
