import React, { useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import ResetPassword from './ResetPassword'
import { useLocation } from 'react-router-dom'
import Icon from '../../components/UI/Icons/Icons'
import { useResetPasswordMutation } from '../../store/api/auth.api'
import AppPrimaryButton from '../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useSuccessMessage } from '../../hooks/useSuccessMessage'
import { requiredValidator } from '../../helpers/validators'
import { useTranslation } from 'react-i18next'

const ForgotPassword = () => {
  const [isPopUpVisible, setIsPopUpVisible] = useState(false)

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const email = params.get('email')

  const [resetPassword, { isSuccess, isLoading }] = useResetPasswordMutation()

  const { t } = useTranslation('guest')

  const onFinish = (values: { email: string }) => {
    resetPassword(values)
  }

  useSuccessMessage(t('forgotPassword.successMessage'), isPopUpVisible)

  useEffect(() => {
    if (isSuccess) {
      setIsPopUpVisible(true)
      setTimeout(() => setIsPopUpVisible(false), 100)
    }
  }, [isSuccess])

  if (code && email) {
    return <ResetPassword code={code} email={email} />
  }

  return (
    <>
      <div className="login-box login-box-forgot-password">
        <Form name="login-form" layout="vertical" onFinish={onFinish} className="form-forgot-password">
          <div>
            <p className="form-title forgot-password-form-title">{t('forgotPassword.headerTitle')}</p>
            <p>{t('forgotPassword.description')}</p>
          </div>
          <div>
            <div className="form-input-label">{t('forgotPassword.emailLabel')}</div>
            <Form.Item
              name="email"
              rules={[
                { required: true, validator: requiredValidator(t('forgotPassword.emailRequired')) },
                { type: 'email', message: t('forgotPassword.invalidEmail') },
              ]}
            >
              <Input size="large" placeholder="Email" />
            </Form.Item>
          </div>
          <Form.Item className="form-item-forgot-password-button-wrapper">
            <AppPrimaryButton size="middle" type="primary" htmlType="submit" loading={isLoading}>
              {t('forgotPassword.nextButton')}
              <Icon name={'buttonRight'} />
            </AppPrimaryButton>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default ForgotPassword
