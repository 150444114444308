import React from 'react'
import Chat from '../../components/Chat/Chat'

const PrivateChatRoute = () => {
  return (
    <>
      <Chat />
    </>
  )
}

export default PrivateChatRoute
