import React, { memo } from 'react'
import { Button, ButtonProps } from 'antd'
import './transparentButton.less'

const TransparentButton = ({ title, ...props }: ButtonProps) => {
  return (
    <Button id="transparent-button" {...props}>
      {title}
    </Button>
  )
}

export default memo(TransparentButton)
