import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../../baseQueryWithReauth'
import { IAccount } from '../../../types/account.type'

export interface CreateAccountMutationBody {
  name: string
  email: string
  accountName: string
}

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAccounts: builder.mutation({
      query: (body) => ({
        url: `admin/accounts/lookup`,
        method: 'POST',
        body,
      }),
    }),
    getAccountOwner: builder.query<{ userId: string }, string>({
      query: (accountId) => ({
        url: `admin/accounts/owner/${accountId}`,
        method: 'GET',
      }),
    }),
    createAccount: builder.mutation<IAccount, CreateAccountMutationBody>({
      query: (body) => ({
        url: `admin/accounts`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetAccountsMutation, useLazyGetAccountOwnerQuery, useCreateAccountMutation } = accountsApi
