import React from 'react'
import { Tabs, TabsProps } from 'antd'
import { useAppSelector } from '../../hooks/appHook'
import IntegrationTabContent from './components/IntegrationTabContent'
import BrandingTabContent from './components/BrandingTabContent'
import CallToActionTabContent from './components/CallToActionTabContent'
import SettingsTabContent from './components/SettingsTabContent'
import AIAgentPageHeader from './components/AIAgentPageHeader/AIAgentPageHeader'
import './aiAgent.less'
import { useTranslation } from 'react-i18next'

const AIAgent = () => {
  const { t } = useTranslation('aiAgent')
  const { agents } = useAppSelector((state) => state.agents)

  const items: TabsProps['items'] = [
    {
      key: 'settings',
      label: t('settings'),
      children: <SettingsTabContent />,
    },
    {
      key: 'cta',
      label: t('callToAction'),
      children: <CallToActionTabContent />,
    },
    // {
    //   key: 'chat page settings',
    //   label: t('chatSettings'),
    //   children: <ChatSettingsTabContent />,
    // },
    {
      key: 'widget',
      label: t('widget'),
      children: <BrandingTabContent />,
    },
    {
      key: 'integration',
      label: t('integration'),
      children: <IntegrationTabContent />,
    },
  ]

  return (
    <>
      <AIAgentPageHeader title={t('title')} />
      <div className="container">
        {agents.length ? (
          <Tabs defaultActiveKey="settings" items={items} destroyInactiveTabPane />
        ) : (
          <div className="empty-storages-container">{t('emptyStorageMessage')}</div>
        )}
      </div>
    </>
  )
}

export default AIAgent
