import React, { ChangeEvent, memo, useCallback } from 'react'
import { Form } from 'antd'
import Icon from '../../../../../components/UI/Icons/Icons'
import { requiredValidator } from '../../../../../helpers/validators'
import AppAuthInput from '../../../../../components/UI/Input/AppAuthInput'
import { useAppSelector } from '../../../../../hooks/appHook'
import { useOnboardingActions } from '../../../../../hooks/actions'
import { shallowEqual } from 'react-redux'
import { debounce } from 'lodash'

const FirstStep = () => {
  const companyName =
    useAppSelector((state) => state.onboarding.companyName, shallowEqual)

  /** Use custom hooks */
  const {
    setCompanyName,
  } = useOnboardingActions()

  const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value)
  }, [])

  return <Form.Item
    name="companyName"
    rules={[
      {
        required: true,
        validator: requiredValidator('Please enter your company name'),
      },
    ]}
  >
    <AppAuthInput
      data-testid="first-step"
      placeholder="Company"
      style={{ minWidth: 490 }}
      value={companyName}
      prefix={<Icon name="copyright" />}
      onChange={debounce(onChangeInput, 500)}
    />
  </Form.Item>
}

export default memo(FirstStep)
