import React, { useEffect } from 'react'
import { Tabs, TabsProps } from 'antd'
import TestChatButton from '../../../../../components/TestChatButton/TestChatButton'

import { useTranslation } from 'react-i18next'
import { AddNewDataTabRoutes, KnowledgeBaseRoutes } from '../../../../../types/enums/knowledge-base-routes.enum'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const AddNewDataTab = () => {
  const { t } = useTranslation('knowledgeBase')
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.endsWith(KnowledgeBaseRoutes.addData)) {
      const queryParams = location.search
      navigate(`${AddNewDataTabRoutes.addHtmlOrText}${queryParams}`, { replace: true })
    }
  }, [location])

  const items: TabsProps['items'] = [
    {
      key: AddNewDataTabRoutes.addHtmlOrText,
      label: t('addNewDataTab.addHtmlOrText'),
    },
    {
      key: AddNewDataTabRoutes.addDocument,
      label: t('addNewDataTab.addDocument'),
    },
    {
      key: AddNewDataTabRoutes.addDataByUrl,
      label: t('addNewDataTab.addDataByUrl'),
    },
    {
      key: AddNewDataTabRoutes.addDocumentByUrl,
      label: t('addNewDataTab.addDocumentByUrl'),
    },
  ]

  const handleTabChange = (key: string) => {
    const queryParams = location.search
    navigate(`${key}${queryParams}`, { replace: true })
  }

  return (
    <div className="add-new-data-tab">
      <div className="tab-header">
        <div className="header-description">
          <h2>{t('addNewDataTab.headerTitle')}</h2>
          <p>{t('addNewDataTab.description')}</p>
        </div>
        <TestChatButton />
      </div>
      <Tabs
        activeKey={location.pathname.split('/').pop()!}
        items={items}
        onChange={handleTabChange}
        destroyInactiveTabPane
      />
      <Outlet />
    </div>
  )
}

export default AddNewDataTab
