import React, { useEffect } from 'react'
import { Form, Modal } from 'antd'
import Icon from '../Icons/Icons'
import AppAuthInput from '../Input/AppAuthInput'
import { useAppConfig, useAppSelector } from '../../../hooks/appHook'
import { useAppStateActions } from '../../../hooks/actions'
import { useCreateUserMutation } from '../../../store/api/users.api'
import { useLazyGetUsersQuery } from '../../../store/api/users.api'
import { useSuccessMessage } from '../../../hooks/useSuccessMessage'
import AppPrimaryButton from '../AppPrimaryButton/AppPrimaryButton'
import { useTranslation } from 'react-i18next'

const InviteToTeamModal = () => {
  const [formInviteToTeam] = Form.useForm()
  const [sendInvite, { isSuccess: isSuccessSendInvite, isLoading: isSendingInvite }] = useCreateUserMutation()
  const { setIsInviteTeamModalOpen } = useAppStateActions()
  const { isInviteToTeamModalOpen } = useAppSelector((state) => state.appState.modals)
  const { appName } = useAppConfig()

  const [getUsers] = useLazyGetUsersQuery()

  const onFinishFormInviteToTeam = (values: { name: string; email: string }) => {
    sendInvite(values)
  }
  const { t } = useTranslation('components')
  useEffect(() => {
    if (isSuccessSendInvite) {
      setIsInviteTeamModalOpen(false)
      getUsers()
    }
  }, [isSuccessSendInvite])

  useSuccessMessage(t('ui.modals.inviteToTeamModal.invitationSentSuccess'), isSuccessSendInvite)

  return (
    <Modal
      open={isInviteToTeamModalOpen}
      footer={
        <AppPrimaryButton
          htmlType="submit"
          id="app-primary-button"
          loading={isSendingInvite}
          onClick={() => formInviteToTeam.submit()}
        >
          {t('ui.modals.inviteToTeamModal.sendInvitationButton')}
          <Icon name="buttonRight" />
        </AppPrimaryButton>
      }
      onCancel={() => {
        setIsInviteTeamModalOpen(false)
        formInviteToTeam.resetFields()
      }}
    >
      <div>
        <h2>{t('ui.modals.inviteToTeamModal.title')}</h2>
        <p>{t('ui.modals.inviteToTeamModal.description', { name: appName })}</p>
        <Form
          name="normal_login"
          form={formInviteToTeam}
          className="login-form"
          style={{ padding: '20px 0' }}
          onFinish={onFinishFormInviteToTeam}
        >
          <Form.Item name="name" rules={[{ required: true }]}>
            <AppAuthInput
              prefix={<Icon name={'inputProfileCircle'} />}
              placeholder={t('ui.modals.inviteToTeamModal.namePlaceholder')}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { type: 'email', message: t('ui.modals.inviteToTeamModal.emailAlreadyExists') },
              { required: true },
            ]}
          >
            <AppAuthInput
              prefix={<Icon name={'inputSms'} />}
              placeholder={t('ui.modals.inviteToTeamModal.emailPlaceholder')}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default InviteToTeamModal
