import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyGetAccountQuery } from '../../store/api/account.api'
import { useAppSelector } from '../../hooks/appHook'
import { Spin } from 'antd'

const Homepage = () => {
  const navigate = useNavigate()
  const [getAccount] = useLazyGetAccountQuery()
  const { account } = useAppSelector((state) => state.account)

  useEffect(() => {
    const fetchAccountAndRedirect = async () => {
      try {
        if (!account?.id) {
          const result = await getAccount().unwrap()
          if (result?.account.id) {
            navigate(`/c/${result?.account.id}`)
          } else {
            navigate('signin')
          }
        } else {
          navigate(`/c/${account.id}`)
        }
      } catch (error) {
        console.error('Error fetching account:', error)
      }
    }

    fetchAccountAndRedirect()
  }, [account, getAccount, navigate])

  return <Spin size="large" spinning={true} />
}

export default Homepage
