import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Input } from 'antd'
import { IAuth } from '../../types/auth.type'
import AppPrimaryButton from '../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { FORGOT_PASSWORD_ROUTE } from '../../config/consts'
import { useSignInMutation, useSignUpMutation } from '../../store/api/auth.api'
import Checkbox from 'antd/es/checkbox/Checkbox'
import { minLengthValidator, requiredValidator } from '../../helpers/validators'
import { accountApi, useLazyGetAccountQuery } from '../../store/api/account.api'

import '../../layouts/guestLayout/guestLayout.less'
import SingInInfoBox from '../../components/singIn-infoBox'
import { useTranslation } from 'react-i18next'
import { useAppConfig } from '../../hooks/appHook'
import { userResetState } from '../../store/slices/appState.slice'

interface Props {
  isSignup?: boolean
}

const SignIn = ({ isSignup }: Props) => {
  const dispatch = useDispatch()
  const { appName } = useAppConfig()
  const { t } = useTranslation('guest')
  const [signIn, { status: signInStatus }] = useSignInMutation()
  const [signUp, { status: signUpStatus }] = useSignUpMutation()

  const [getAccount] = useLazyGetAccountQuery()
  const navigate = useNavigate()

  const fetchAccountAndNavigate = async () => {
    const result = await getAccount().unwrap()
    if (result?.account?.id) {
      navigate(`/c/${result.account.id}`)
    }
  }

  const onFinish = async (values: IAuth) => {
    if (isSignup) {
      await signUp(values)
      fetchAccountAndNavigate()
    } else {
      localStorage.clear()
      dispatch(userResetState())
      dispatch(accountApi.util.resetApiState())
      const signInResult = await signIn(values)

      if (!('error' in signInResult && signInResult.error)) {
        fetchAccountAndNavigate()
      }
    }
  }

  return (
    <>
      <div className="loginWrapper">
        {isSignup && <SingInInfoBox />}
        <div className={`login-box ${isSignup ? 'login-box-borders' : 'login-box-border'}`}>
          <Form name="login-form" onFinish={onFinish} layout="vertical">
            <p data-testid= "form-title" className="form-title">
              {isSignup ? t('signIn.createAccount', { name: appName }) : t('signIn.signInToAccount')}
            </p>
            <p></p>
            {isSignup && (
              <Form.Item
                name="name"
                rules={[{ required: true, validator: requiredValidator(t('signIn.nameRequired')) }]}
              >
                <div>
                  <p style={{ marginTop: 0 }}>Name</p>
                  <Input data-testid="user-name" size="large"></Input>
                </div>
              </Form.Item>
            )}
            <Form.Item
              name="email"
              rules={[
                { required: true, validator: requiredValidator(t('signIn.emailRequired')) },
                {
                  type: 'email',
                  message: t('signIn.validEmail'),
                },
              ]}
            >
              <div>
                <p>Email</p>
                <Input data-testid="user-email" size="large"></Input>
              </div>
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, validator: requiredValidator(t('signIn.passwordRequired')) },
                isSignup ? { validator: minLengthValidator(8, t('signIn.passwordMinLength')) } : {},
              ]}
            >
              <div>
                <p>Password</p>
                <Input.Password data-testid="user-password" size="large"></Input.Password>
              </div>
            </Form.Item>
            <Form.Item>
              <div className="login-form-actions">
                {isSignup && (
                  <Form.Item
                    name="agree"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error(t('signIn.termsAndConditions'))),
                      },
                    ]}
                  >
                    <Checkbox>
                      <span data-testid="terms-and-conditions" style={{ color: '#FF5996', marginRight: 2 }}>*</span>
                      {t('signIn.termsAndConditions')}
                      <Link to="https://ioni.ai/terms" target="_blank">
                        Terms & Conditions
                      </Link>{' '}
                      and{' '}
                      <Link to="https://ioni.ai/privacy-policy" target="_blank">
                        Privacy Policy
                      </Link>
                    </Checkbox>
                  </Form.Item>
                )}
                {!isSignup && (
                  <div data-testid="forgot-password" id="forgot-password-text-container">
                    <Link to={FORGOT_PASSWORD_ROUTE}>{t('signIn.forgotPassword')}</Link>
                  </div>
                )}
              </div>
            </Form.Item>

            <Form.Item>
              <AppPrimaryButton
                type="primary"
                size="middle"
                data-testid="submit-button"
                htmlType="submit"
                loading={signInStatus === 'pending' || signUpStatus === 'pending'}
              >
                {isSignup ? t('signIn.createAccountButton') : t('signIn.signInButton')}
              </AppPrimaryButton>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  )
}

export default SignIn
