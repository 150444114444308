import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Form, notification, Typography } from 'antd'
import { FileDoneOutlined, DeleteOutlined } from '@ant-design/icons'
import AppPrimaryButton from '../../../../../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import './addFileTab.less'
import { useUploadFileMutation } from '../../../../../../../../store/api/uploads.api'
import { useCreateDocumentMutation } from '../../../../../../../../store/api/document.api'
import { useAppSelector } from '../../../../../../../../hooks/appHook'
import { useLocation, useNavigate } from 'react-router-dom'
import { truncateText } from '../../../../../../../../helpers/strings'
import { useTranslation } from 'react-i18next'
import { KnowledgeBaseRoutes } from '../../../../../../../../types/enums/knowledge-base-routes.enum'

const AddFileTab = () => {
  const [filesData, setFilesData] = useState<File[]>([])
  const maxSize = 20 * 1024 * 1024 // 20 MB
  const navigate = useNavigate()
  const location = useLocation()
  const { currentStorageId } = useAppSelector((state) => state.storage)
  const { t } = useTranslation('knowledgeBase')

  const [uploadFile, uploadFileMutation] = useUploadFileMutation()
  const [createDocument, createDocumentMutation] = useCreateDocumentMutation()
  const isLoading = createDocumentMutation.status === 'pending' || uploadFileMutation.status === 'pending'

  const onDropAccepted = (files: File[]) => {
    setFilesData([files[0]])
  }

  const onDropRejected = (fileRejections: any) => {
    fileRejections.forEach(({ errors }: any) => {
      errors.forEach((err: any) => {
        if (err.code === 'file-too-large') {
          notification.error({ message: t('addNewDataTab.addFileTab.errorMessage') })
        } else if (err.code === 'file-invalid-type') {
          notification.error({ message: t('addNewDataTab.addFileTab.errorMessage') })
        }
      })
    })
  }

  const onSubmit = async () => {
    const formData = new FormData()
    formData.append('file', filesData[0])

    const searchParams = new URLSearchParams(location.search)

    try {
      const result = await uploadFile({ body: formData }).unwrap()
      if (result?.s3Uri) {
        const createdDocument = await createDocument({
          storageId: currentStorageId,
          file: {
            s3Uri: result.s3Uri,
            publicUrl: result.url,
          },
        }).unwrap()
        if (createdDocument.id) {
          notification.success({ message: t('addNewDataTab.addFileTab.successMessage') })
          searchParams.set('documentId', createdDocument.id)
          navigate(`/storage/${KnowledgeBaseRoutes.review}?${searchParams.toString()}`)
        }
      }
    } catch (e: any) {
      notification.error({
        message: t('addNewDataTab.addFileTab.errorMessage'),
      })
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/markdown': ['.md'],
      'text/plain': ['.txt'],
    },
    maxSize,
    onDropAccepted,
    onDropRejected,
    maxFiles: 1,
  })

  const onDeleteFileFromUploads = (fileName: string) => {
    const filteredFiles = filesData.filter((file) => file.name !== fileName)
    setFilesData(filteredFiles)
  }

  return (
    <Form className="form-ai-helper-col-wrapper form-ai-helper-col-wrapper-align-left" onFinish={onSubmit}>
      <h3>{t('addNewDataTab.addFileTab.description')}</h3>

      <div {...getRootProps({ className: 'drag-and-drop' })}>
        <input {...getInputProps()} />
        {filesData?.length ? (
          filesData.map((file) => (
            <div key={file.name}>
              <FileDoneOutlined className="file-icon" />
              <Typography className="file-text">{truncateText(file.name, 40)}</Typography>
              <DeleteOutlined
                className="delete-file-icon"
                onClick={(e) => {
                  e.stopPropagation()
                  onDeleteFileFromUploads(file.name)
                }}
              />
            </div>
          ))
        ) : (
          <p>{t('addNewDataTab.addFileTab.dragDrop')}</p>
        )}
      </div>
      <AppPrimaryButton
        type="primary"
        size="middle"
        htmlType="submit"
        className="submit-button"
        disabled={!filesData?.length}
        loading={isLoading}
      >
        {t('addNewDataTab.addFileTab.uploadFile')}
      </AppPrimaryButton>
    </Form>
  )
}

export default AddFileTab
