import React, { memo, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { first } from 'lodash'
import { useAppSelector } from '../../../../hooks/appHook'
import AiHelperHeader from '../../../../components/AIHelperHeader/aiHelperHeader'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useAppStateActions } from '../../../../hooks/actions'
import CustomSelect from '../../../../components/CustomSelect/CustomSelect'
import { useGetAgentListQuery } from '../../../../store/api/agent.api'
import CreateNewAIAgentModal from '../CreateNewAIAgentModal/CreateNewAIAgentModal'
import { setCurrentAgentId } from '../../../../store/slices/agents.slice'
import TransparentButton from '../../../../components/UI/TransparentButton/TransparentButton'
import { useTranslation } from 'react-i18next'

const QUERY_AGENT_ID_NAME = 'agentId'

const AIAgentPageHeader = ({ title }: { title: string }) => {
  const { t } = useTranslation('aiAgent')
  const dispatch = useDispatch()
  const locate = useLocation()
  const navigate = useNavigate()

  const { setIsCreateAIAgentModalOpen } = useAppStateActions()
  const { account } = useAppSelector((state) => state.account)
  const { currentAgentId } = useAppSelector((state) => state.agents)
  const { currentStorageId } = useAppSelector((state) => state.storage)
  const { data: agents, refetch } = useGetAgentListQuery()

  const queryParams = new URLSearchParams(locate.search)

  const firstAgentInAgents = useMemo(() => {
    return first(agents) || null
  }, [agents])

  const selectOptions = useMemo(() => {
    return (
      agents?.map(({ id, name }) => {
        return {
          value: id,
          label: id === account.defaultAgentId ? `${name} (${t('aiAgentPageHeader.default')})` : name,
        }
      }) || []
    )
  }, [agents, account, currentAgentId])

  const checkIsAgentAvailable = async (agentId: string) => {
    const result = await refetch()
    if ('data' in result) {
      return result.data?.some((agent) => agent.id === agentId)
    }
  }

  const onChangeSelect = (value: any) => dispatch(setCurrentAgentId(value))


  const onPressCreateAiAgent = () => setIsCreateAIAgentModalOpen(true)


  const handleOnPressTestAgent = () => {
    if (account?.id && currentAgentId) {
      window.open(
        `${location.origin}/c/${account.id}?agentId=${currentAgentId}${
          currentStorageId && `&storageId=` + currentStorageId
        }`,
        '_blank',
      )
    }
  }

  useEffect(() => {
    const handleAgentAndQueryParams = async () => {
      if (!agents || agents.length === 0) {
        return
      }

      if (!currentAgentId && firstAgentInAgents?.id) {
        dispatch(setCurrentAgentId(firstAgentInAgents.id))
        return
      }

      const isAgentAvailable = await checkIsAgentAvailable(currentAgentId)
      if (!isAgentAvailable) {
        dispatch(setCurrentAgentId(firstAgentInAgents?.id || ''))
        return
      }

      const existingQueryId = queryParams.get(QUERY_AGENT_ID_NAME)
      if (existingQueryId !== currentAgentId) {
        queryParams.set(QUERY_AGENT_ID_NAME, currentAgentId)
        navigate({ search: queryParams.toString() }, { replace: true })
      }
    }

    handleAgentAndQueryParams().catch((error) => console.error('Error handling agent or query params:', error))
  }, [agents, currentAgentId, firstAgentInAgents])

  return (
    <div className="page-header">
      <CreateNewAIAgentModal />
      <AiHelperHeader title={title} paragraph="" />
      <div className="header-right-content">
        {agents?.length ? (
          <CustomSelect
            createButtonTitle={t('aiAgentPageHeader.createAIAgent')}
            onChange={onChangeSelect}
            options={selectOptions}
            initialStatus={currentAgentId}
            key={currentAgentId}
            onPressCreteButton={onPressCreateAiAgent}
          />
        ) : (
          <Button type="text" icon={<PlusOutlined />} onClick={onPressCreateAiAgent} className="create-storage-button">
            {t('aiAgentPageHeader.createNewAgent')}
          </Button>
        )}
        {agents?.length && (
          <TransparentButton
            onClick={handleOnPressTestAgent}
            title={t('aiAgentPageHeader.testAgent')}
            style={{ width: 150 }}
            className="transparent-button-small"
          />
        )}
      </div>
    </div>
  )
}

export default memo(AIAgentPageHeader)
