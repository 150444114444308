import React from 'react'
import { Message } from '../../../types/storage.type'
import { Collapse, Divider } from 'antd'
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons'
import ChatMessage from '../../Chat/ChatMessage/ChatMessage'
import './ConversationLikedMessages.less'
import { useTranslation } from 'react-i18next'

type IProps = {
  messages: Message[]
  showMessagesWith: 'dislike' | 'like'
}

const ConversationLikedMessages = ({ messages, showMessagesWith }: IProps) => {
  const { t } = useTranslation('conversations')

  const filteredMessages = messages.filter((m) => m.feedback?.some((f) => f.rating === showMessagesWith))

  if (!filteredMessages || !filteredMessages?.length) {
    return <></>
  }

  return (
    <div className="conversation-liked-messages">
      <Collapse bordered={false} size="large" expandIconPosition="end">
        <Collapse.Panel
          header={
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {showMessagesWith === 'like' ? (
                <>
                  {t('conversation.conversationLikedMessages.likedMessages')} <LikeOutlined className="rating-icon" />
                </>
              ) : (
                <>
                  {t('conversation.conversationLikedMessages.dislikedMessages')}{' '}
                  <DislikeOutlined className="rating-icon" />
                </>
              )}
            </div>
          }
          key={showMessagesWith}
          style={{ userSelect: 'none', position: 'relative' }}
        >
          <ul>
            {filteredMessages.map((message, index) => (
              <React.Fragment key={message.id}>
                <li>
                  <ChatMessage
                    message={message}
                    showRoles={false}
                    conversationId="1"
                    allowLikeDislikeActions={false}
                    refetchConversation={() => undefined}
                  />
                </li>
                {index < filteredMessages.length - 1 && <Divider style={{ margin: '10px 0' }} />}
              </React.Fragment>
            ))}
          </ul>
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

export default ConversationLikedMessages
